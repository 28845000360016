/**
 * @file   src\store\actions\authAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosClient } from '../../config/axiosConfig';
import { IAuthRequest } from '../../interfaces/authInterface';
import { AuthApi } from '../../utils/apiUrls';
import { getAuthToken, getRefreshToken } from '../../utils/localStorage';

// User authentication api call.
export const login = createAsyncThunk('/auth/login', async (loginRequest: IAuthRequest, { rejectWithValue }) => {
  try {
    const headers = { APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(AuthApi.LOGIN, loginRequest, {headers});
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response);
  }
});

export const resetPassword = createAsyncThunk('/auth/resetpwd', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(AuthApi.CHANGE_PASSWORD, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const forgotpwd = createAsyncThunk('/auth/forgotpwd', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(AuthApi.FORGOT_PASSWORD, payload, {headers});
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const newpwd = createAsyncThunk('/auth/newpwd', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${payload.t}`, APIKey: process.env.REACT_APP_API_KEY };
    const info = {
      newpwd: payload.newpwd,
      otp: payload.otp,
    };
    console.log("pay", info);
    const data = await axiosClient.post(AuthApi.NEW_PASSWORD, info, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const getpresigned = createAsyncThunk('/refreshtoken', async (payload: any, { rejectWithValue }) => {
  try {
    let info = {
      token: getRefreshToken(),
    }
    const headers = { APIKey: process.env.REACT_APP_API_KEY };
    const queryParams = new URLSearchParams(info).toString();
    const data = await axiosClient.get(`${AuthApi.GET_REFRESH_TOKEN}?${queryParams}`, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

