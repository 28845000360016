/**
 * @file   src\store\actions\authAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';

// User authentication api call.
export const changeMenuActiveStatus = createAsyncThunk('/changeMenuActiveStatus', async (payload: boolean, { rejectWithValue }) => {
  return payload;
});

export const changeLoaderStatus = createAsyncThunk('/changeLoaderStatus', async (payload: boolean, { rejectWithValue }) => {
  return payload;
});

