const IcVisible = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <g id="visible" transform="translate(-286 -97)">
        <rect id="Rectangle_866" data-name="Rectangle 866" width="23" height="23" transform="translate(286 97)" fill="#773737" opacity="0" />
        <path
          id="icon-visibility"
          d="M20.873,10.968A11.694,11.694,0,0,0,10.5,4.5,11.7,11.7,0,0,0,.127,10.968a1.179,1.179,0,0,0,0,1.064A11.694,11.694,0,0,0,10.5,18.5a11.7,11.7,0,0,0,10.373-6.468A1.179,1.179,0,0,0,20.873,10.968ZM10.5,16.75a5.25,5.25,0,1,1,5.25-5.25A5.25,5.25,0,0,1,10.5,16.75ZM10.5,8a3.475,3.475,0,0,0-.923.138,1.745,1.745,0,0,1-2.439,2.439A3.492,3.492,0,1,0,10.5,8Z"
          transform="translate(287 97.5)"
          fill="#cdd4d9"
        />
      </g>
    </svg>
  );
};

export default IcVisible;
