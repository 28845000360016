/**
 * @file   src\store\slices\authSlices.ts
 * @brief  This file is responsible for creating auth slices to call actions and state management.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { changeLoaderStatus, changeMenuActiveStatus } from '../actions/generalAction';
import { GeneralProperty, LoaderProperty } from '../../interfaces/GeneralInterface';

// Initial state.
const generalState: GeneralProperty = {
  menuActiveStatus: false,
};
const loaderState: LoaderProperty = {
  loader: false,
}

// Config user slice.
export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    generalState,
    loaderState,
  },
  reducers: {
    resetGeneralState: (state) => {
      state.generalState = generalState;
    },
    resetLoaderState: (state) => {
      state.loaderState = loaderState;
    },

  },
  extraReducers(builder) {
    builder.addCase(changeMenuActiveStatus.pending, (state) => {
      state.generalState.menuActiveStatus = false;
    });
    builder.addCase(changeMenuActiveStatus.fulfilled, (state, action) => {
      const data = action?.payload || false;
      state.generalState.menuActiveStatus = data;
    });
    builder.addCase(changeMenuActiveStatus.rejected, (state, action: any) => {
      state.generalState.menuActiveStatus = false;
    });
    builder.addCase(changeLoaderStatus.pending, (state) => {
      state.loaderState.loader = false;
    });
    builder.addCase(changeLoaderStatus.fulfilled, (state, action) => {
      const data = action?.payload || false;
      state.loaderState.loader = data;
    });
    builder.addCase(changeLoaderStatus.rejected, (state, action: any) => {
      state.loaderState.loader = false;
    });
  },

});
// Export auth actions.
export const { resetGeneralState, resetLoaderState } = generalSlice.actions;
// Export reducer.
export default generalSlice.reducer;
