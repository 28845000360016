const IcInputAdd = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Group_4076" data-name="Group 4076" transform="translate(-6759 9558)">
        <rect id="Rectangle_864" data-name="Rectangle 864" width="20" height="20" transform="translate(6759 -9558)" fill="#fff" opacity="0" />
        <g id="Group_4075" data-name="Group 4075" transform="translate(5929 -10308)">
          <path
            id="Path_12644"
            data-name="Path 12644"
            d="M-5920.37,10326.629v-7.258h-7.258A1.372,1.372,0,0,1-5929,10318a1.372,1.372,0,0,1,1.371-1.371h7.258v-7.258A1.372,1.372,0,0,1-5919,10308a1.372,1.372,0,0,1,1.371,1.371v7.258h7.257A1.372,1.372,0,0,1-5909,10318a1.372,1.372,0,0,1-1.371,1.371h-7.257v7.258A1.372,1.372,0,0,1-5919,10328,1.372,1.372,0,0,1-5920.37,10326.629Z"
            transform="translate(6759 -9558)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcInputAdd;
