/**
 * @file   src\components\Header.tsx
 * @brief  Header component.
 * @date November, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../assets/styles/header.scss';
import { Navbar, Button } from 'react-bootstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Strings from '../assets/strings/Strings.json';
import logoInner from '../assets/img/Logo_Inner.png';
import Profpic from '../assets/img/user_pic.png';
import IcMenu from '../assets/img/icons/Menu';
import { useAppDispatch, useAppSelector } from '../hooks';
import { changeMenuActiveStatus } from '../store/actions/generalAction';

const Header = () => {
  const dispatch: ThunkDispatch<any, undefined, AnyAction> = useAppDispatch();
  const ResponseData = useAppSelector((RootReducer) => RootReducer.general.generalState.menuActiveStatus);
  const handleOpen = () => {
    dispatch(changeMenuActiveStatus(!ResponseData));
  };
  return (
    <Navbar>
      <Navbar.Brand className="ps-4" href="/manageusers">
        {' '}
        <img src={logoInner} alt="logo" />
      </Navbar.Brand>
      <Button className="menu" name="menu" onClick={handleOpen}>
        <IcMenu />
      </Button>
      <div className="user-det">
        <img src={Profpic} alt="profilePic" />
        <span>{Strings.User.Name}</span>
      </div>
    </Navbar>
  );
};

export default Header;
