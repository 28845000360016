const IcLogout = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_4071" data-name="Group 4071" transform="translate(-44 -891.297)">
        <rect id="Rectangle_858" data-name="Rectangle 858" width="28" height="28" transform="translate(44 891.297)" fill="none" stroke="none" />
        <g id="login-1--arrow-enter-frame-left-login-point-rectangle" transform="translate(47 894)">
          <path
            id="Rectangle_1097"
            data-name="Rectangle 1097"
            d="M2.813,9.268c.038-1.54.09-2.759.141-3.679a2.65,2.65,0,0,1,2.38-2.562c1.157-.114,2.788-.214,4.954-.214s3.8.1,4.954.214a2.65,2.65,0,0,1,2.38,2.562c.1,1.733.2,4.519.2,8.52s-.1,6.788-.2,8.521a2.65,2.65,0,0,1-2.38,2.562c-1.157.114-2.788.214-4.954.214s-3.8-.1-4.954-.214a2.65,2.65,0,0,1-2.38-2.562c-.051-.921-.1-2.139-.141-3.679"
            transform="translate(4.181 -2.813)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Union"
            d="M22.949,23.729a.87.87,0,0,1-1.456-.654c-.046-.692-.1-1.552-.129-2.486l-10.492-.382a1.339,1.339,0,0,1-1.341-1.135,6.659,6.659,0,0,1,0-1.576,1.339,1.339,0,0,1,1.341-1.135l10.492-.382q.046-1.245.13-2.487a.87.87,0,0,1,1.456-.654c.526.445,1.217,1.067,2.09,1.924A14.846,14.846,0,0,1,27.5,17.721a1.1,1.1,0,0,1,0,1.124A14.859,14.859,0,0,1,25.04,21.8c-.874.857-1.565,1.48-2.091,1.925Z"
            transform="translate(-9.484 -6.988)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcLogout;
