/**
 * @file   src\components\Layout.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date November, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../assets/styles/main.scss';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { useAppSelector } from '../hooks';

const Layout = () => {
  const [isActive, setActive] = useState<boolean | undefined>();
  const ResponseData = useAppSelector((RootReducer) => RootReducer.general.generalState.menuActiveStatus);

  useEffect(() => {
    if (ResponseData !== null) {
      setActive(ResponseData);
    }
  }, [ResponseData]);

  return (
    <div className={isActive ? 'sidenav-toggled' : ''}>
      <Header />
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default Layout;
