const IcInputDelete = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Group_4077" data-name="Group 4077" transform="translate(-6707 9559)">
        <g id="Iconly_Light_Delete" data-name="Iconly/Light/Delete" transform="translate(6708.25 -9558.75)">
          <g id="Delete" transform="translate(0 -0.001)">
            <path
              id="Stroke_1"
              data-name="Stroke 1"
              d="M6.671,11.819c-1.277,0-2.523-.013-3.731-.038a2.832,2.832,0,0,1-2.88-2.7C-.236,6.413-.743.123-.748.06A.75.75,0,0,1-.06-.748.75.75,0,0,1,.748-.06c.005.063.51,6.33.8,8.977a1.343,1.343,0,0,0,1.421,1.364c2.319.049,4.784.05,7.327,0a1.364,1.364,0,0,0,1.484-1.375c.291-2.624.8-8.908.8-8.971a.75.75,0,0,1,.808-.687.75.75,0,0,1,.687.808c-.005.063-.514,6.37-.808,9.016a2.838,2.838,0,0,1-2.947,2.71C9.087,11.808,7.866,11.819,6.671,11.819Z"
              transform="translate(2.05 7.062)"
              fill="none"
            />
            <path
              id="Stroke_3"
              data-name="Stroke 3"
              d="M15.933,1.25H0A.75.75,0,0,1-.75.5.75.75,0,0,1,0-.25H15.933a.75.75,0,0,1,0,1.5Z"
              transform="translate(0.75 3.529)"
              fill="none"
            />
            <path
              id="Stroke_5"
              data-name="Stroke 5"
              d="M9.793,4.028A2.3,2.3,0,0,1,7.54,2.181L7.318,1.068A.453.453,0,0,0,6.885.75H2.908a.453.453,0,0,0-.433.318L2.253,2.181A2.3,2.3,0,0,1,0,4.028a.75.75,0,0,1-.75-.75A.75.75,0,0,1,0,2.528a.8.8,0,0,0,.782-.641L1.01.745q0-.024.011-.047A1.955,1.955,0,0,1,2.908-.75H6.885A1.955,1.955,0,0,1,8.772.7q.006.023.011.047l.228,1.142a.8.8,0,0,0,.782.641.75.75,0,1,1,0,1.5Z"
              transform="translate(3.82 0.751)"
              fill="none"
            />
          </g>
        </g>
        <rect id="Rectangle_865" data-name="Rectangle 865" width="20" height="20" transform="translate(6707 -9559)" fill="#fff" opacity="0" />
      </g>
    </svg>
  );
};

export default IcInputDelete;
