const IcDeleteModal = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 112 112">
      <g id="Group_4063" data-name="Group 4063" transform="translate(0 0.003)">
        <circle id="Ellipse_114" data-name="Ellipse 114" cx="56" cy="56" r="56" transform="translate(0 -0.003)" fill="#fff" />
        <g id="recycle-bin-2--remove-delete-empty-bin-trash-garbage" transform="translate(28.999 38.001)">
          <path
            id="Union"
            d="M52.234,16.3a1.846,1.846,0,0,0-1.991-1.958c-3.712.3-10.412.64-21.811.64-11.342,0-18.031-.334-21.754-.635a1.846,1.846,0,0,0-1.991,1.957C5.78,33.34,6.828,42.239,7.46,46.518a6.936,6.936,0,0,0,5.2,5.893,68.541,68.541,0,0,0,15.8,1.51,68.621,68.621,0,0,0,15.8-1.51,6.936,6.936,0,0,0,5.2-5.893c.631-4.279,1.68-13.179,2.773-30.216Z"
            transform="translate(-1.383 -7.305)"
            fill="#ff6e6e"
          />
          <path
            id="Vector_831_Stroke_"
            data-name="Vector 831 (Stroke)"
            d="M18.215,23.012a2.462,2.462,0,0,1,2.7,2.2l1.231,12.308a2.462,2.462,0,1,1-4.9.49L16.012,25.707A2.462,2.462,0,0,1,18.215,23.012Z"
            transform="translate(1.229 -5.306)"
            fill="#811c1c"
            fillRule="evenodd"
          />
          <path
            id="Vector_832_Stroke_"
            data-name="Vector 832 (Stroke)"
            d="M30.939,23.012a2.462,2.462,0,0,0-2.7,2.2L27.012,37.524a2.462,2.462,0,1,0,4.9.49l1.231-12.308A2.462,2.462,0,0,0,30.939,23.012Z"
            transform="translate(3.767 -5.306)"
            fill="#811c1c"
            fillRule="evenodd"
          />
          <path
            id="Union_1"
            data-name="Union 1"
            d="M3.5,11a3.5,3.5,0,0,1,0-7H22a4,4,0,0,1,4-4h2a4,4,0,0,1,4,4H50.5a3.5,3.5,0,0,1,0,7Z"
            transform="translate(0 -0.004)"
            fill="#c11a1a"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcDeleteModal;
