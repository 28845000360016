export const MenuStatus = () => {
  const userInfo = localStorage.getItem('currentActiveMenu');
  if (userInfo !== null) {
    const session = userInfo;
    return session;
  }
  return null;
};

export const SetActivemenuToLocal = (value: string) => {
  localStorage.setItem('currentActiveMenu', value);
};

export const ClearLocal = () => {
  localStorage.removeItem('currentActiveMenu');
};

export const SetLoginData = (value: any) => {
  localStorage.setItem('userAuth', JSON.stringify(value));
};

export const getAuthToken = () => {
  const userInfoString = localStorage.getItem('userAuth');
  let aToken = '';
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    if (userInfo && userInfo.accessToken) {
      aToken = userInfo.accessToken;
    }
  }
  return aToken;
};

export const getRefreshToken = () => {
  const userInfoString = localStorage.getItem('userAuth');
  let aToken = '';
  if (userInfoString) {
    const userInfo = JSON.parse(userInfoString);
    if (userInfo && userInfo.refreshToken) {
      aToken = userInfo.refreshToken;
    }
  }
  return aToken;
};


export const ClearLocalStorage = () => {
  localStorage.clear();
};

export const SetForgotPasswordEmail = (value: string) => {
  localStorage.setItem('forgotPwdEmail', value);
};

export const GetForgotPasswordEmail = () => {
  const forgotPwdEmail = localStorage.getItem('forgotPwdEmail');
  return forgotPwdEmail;
};
