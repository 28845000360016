/**
 * @file   src\containers\AdvertisementList.tsx
 * @brief  advertisement listing page
 * @date February, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Container, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import React, { useEffect, useState, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import Strings from '../assets/strings/Strings.json';
import IcSearch from '../assets/img/icons/Search';
import IcDelete from '../assets/img/icons/Delete';
import IcAdd from '../assets/img/icons/PlusBtn';
import PAInput from '../components/PAInput';
import IcEdit from '../assets/img/icons/Edit';
import IcVideo from '../assets/img/icons/Video';
import IcImage from '../assets/img/icons/Image';
import PASwitch from '../components/PASwitch';
import IcDeleteModal from '../assets/img/icons/DeleteModel';
import { SetActivemenuToLocal } from '../utils/localStorage';
import { useAppDispatch, useAppSelector } from '../hooks';
import { toast } from 'react-toastify';
import { changeLoaderStatus } from '../store/actions/generalAction';
import { AdvertisementListRes, AdvertisementListResponse } from '../interfaces/advertisement';
import { advertisementlist, updateadstatus } from '../store/actions/advertisementAction';
import HomeLoader from '../components/loader/homeLoader';
import PaginationList from '../components/PaginationList';

const AdvertisementList = () => {
  const LoaderData = useAppSelector((RootReducer) => RootReducer.general.loaderState.loader);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [Modal4show, Modal4setShow] = useState(false);
  const Modal4Close = () => Modal4setShow(false);
  const Modal4Show = () => Modal4setShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);


  const [advertisementListRes, setAdvertisementListRes] = useState<AdvertisementListResponse>();
  const [active, setActive] = useState(1);
  const [data, setData] = useState<AdvertisementListRes>();
  const [searchText, setSearchText] = useState('');
  const [selectedAd, setSelectedAd] = useState<number>();

  const GetAdvertisementList = async () => {
    setLoading(true);
    const funReq = {
      page: {
        Size: 10,
        orderby: 'createdat desc',
        pageno: active,
      },
      searchkey: searchText,
    };
    try {
      const { payload } = await dispatch(advertisementlist(funReq) as any);
      if (payload.status === 200) {
        const advertisementList = payload.data.advertisementlist;
        setData(advertisementList);
        setAdvertisementListRes(payload.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(payload.data.error);
      }
    } catch (error) {
      return false;
    }
    dispatch(changeLoaderStatus(false) as any);
    return undefined;
  };

  useEffect(() => {
    GetAdvertisementList();
  }, [active, searchText]);

  useEffect(() => {
    GetAdvertisementList();
  }, []);

  const handleChange = (e: { target: { value: string } }) => {
    setSearchText(e.target.value);
  };

  const moveToEdit = (item: any) => {
    navigate('/AddAdvertisement', { state: { data: item } });
  };

  const handleDeleteConfirm = (adId: number) => {
    setSelectedAd(adId);
    handleShow();
  };


  const Handledelete = async () => {
    handleClose();
    let funReq = {
      adid: selectedAd,
      statusid: 3
    };
    try {
      const { payload } = await dispatch(updateadstatus(funReq) as any);
      if (payload.status === 200) {
        Modal4Show();
        setSelectedAd(0);
        GetAdvertisementList();
      }
      else {
        toast.error(payload.data.error);
      }
    } catch (error) {
      return false;
    }

  };


  const MoveToAdd = () => {
    SetActivemenuToLocal('advertisement');
    navigate('/AddAdvertisement');
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      GetAdvertisementList();
    }
  };

  const handleStatusChange = async (adId: number, isChecked: boolean) => {
    let funReq = {
      adid: adId,
      statusid: isChecked ? 1 : 2
    };
    try {
      const { payload } = await dispatch(updateadstatus(funReq) as any);
      if (payload.status === 200) {
        // GetAdvertisementList();
      }
      else {
        toast.error(payload.data.error);
      }
    } catch (error) {
      return false;
    }
    return undefined;
  };

  return (
    <>
      {loading ? <HomeLoader /> :
        <div className="layout-content">
          <Container fluid className="content-wrap">
            <Row className="align-items-center header mb-3">
              <Col>
                <h2>{Strings.AdvertisementList.heading}</h2>
              </Col>
              <Col lg="auto" className="withbtn">
                <div className="search">
                  <Button id="button-addon1">
                    <IcSearch />
                  </Button>
                  <PAInput id="search" name="search" type="text" onKeyDown={handleKeyPress} onChange={handleChange} value={searchText} placeholder={Strings.ManageUsers.Search} />
                </div>
                <Button variant="secondary" onClick={MoveToAdd}>
                  <IcAdd />
                  {Strings.AdvertisementList.button}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table responsive className={data !== undefined && data.length > 0 ? 'cstm-tble' : 'cstm-tble border-0'}>
                  <thead>
                    <tr>
                      <th>{Strings.Table1.SerialNo}</th>
                      <th>{Strings.Table1.Title}</th>
                      <th>{Strings.Table1.Type}</th>
                      <th>{Strings.Table1.Status}</th>
                      <th>{Strings.Table1.Actions}</th>
                    </tr>
                  </thead>
                  {data !== undefined && data.length > 0 ? (
                    <tbody>
                      {data.map((item, index) => (
                        <tr>
                          <td className="fontWeightCustom">{index + 1}</td>
                          <td>
                            <span>{item.title}</span>
                          </td>
                          <td className="nowrap icn-img fontWeightCustom">
                            {item.typeid === 1 ? <IcImage /> : <IcVideo />}
                            {item.typeid === 1 ? 'Image' : 'Video'}
                          </td>
                          <td>
                            <div className="switch-cstm activein">
                              <PASwitch checked={item.statusid === 1} activetext="Active" inactivetext="Inactive" onChange={(checked: boolean) => handleStatusChange(item.id, checked)} />
                            </div>
                          </td>
                          <td className="nowrap">
                            <Button variant="primary edit" onClick={() => moveToEdit(item)}>
                              <IcEdit />
                              {Strings.Table1.Edit}
                            </Button>
                            <Button variant="primary delete" onClick={() => handleDeleteConfirm(item.id)}>
                              <IcDelete />
                              {Strings.Table1.Delete}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="noResultDiv" colSpan={7}>
                          <center>
                            <p>{Strings.Table.NoResults}</p>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
                {advertisementListRes?.totalrecordcount !== 0 && (
                  <div className="cstm-pagenation">
                    <PaginationList count={advertisementListRes?.totalrecordcount} active={active} limit={10} setActive={setActive} />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <Modal size="lg" className="cstmmodel" show={Modal4show} onHide={Modal4Close} centered>
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="icon d-flex justify-content-center">
                <IcDeleteModal />
              </div>
              <h1 className="text-center">{Strings.DeleteModal4.heading}</h1>
              <p className="text-center">{Strings.DeleteModal4.Paragraph}</p>
            </Modal.Body>
          </Modal>
          <Modal size="lg" className="cstmmodel" show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="icon d-flex justify-content-center">
                {' '}
                <IcDeleteModal />
              </div>
              <h1 className="text-center">{Strings.DeleteAdvertisementModal.heading}</h1>
              <p className="text-center">{Strings.DeleteAdvertisementModal.Paragraph}</p>
              <div className="buttons mt-4 text-center">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={Handledelete}>
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      }
    </>
  );
};

export default AdvertisementList;
