const IcImage = (props: any) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 32 32">
        <g id="image" transform="translate(5.307 5.307)">
          <rect id="Rectangle_849" data-name="Rectangle 849" width="32" height="32" rx="8" transform="translate(-5.307 -5.307)" fill="#d7c2ff" />
          <rect
            id="Rectangle_852"
            data-name="Rectangle 852"
            width="16"
            height="16"
            rx="2"
            transform="translate(2.693 2.693)"
            fill="#fffcf9"
            stroke="#9572d7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <circle
            id="Ellipse_119"
            data-name="Ellipse 119"
            cx="1"
            cy="1"
            r="1"
            transform="translate(6.693 6.693)"
            fill="#fffcf9"
            stroke="#9572d7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_12640"
            data-name="Path 12640"
            d="M19.1,13.974,14.7,10,5,18.742"
            transform="translate(-0.463 -0.101)"
            fill="#fffcf9"
            stroke="#9572d7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  };
  
  export default IcImage;
  