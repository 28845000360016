/**
 *@file PASwitch.tsx
 *@brief Reusable input component
 * @date December, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import './PASwitch.scss';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const PASwitch = ({ checked, activetext, inactivetext, disabled, onChange }: any) => {
  return <BootstrapSwitchButton checked={checked} onlabel={activetext} onChange={onChange} offlabel={inactivetext} disabled={disabled} />;
};

export default PASwitch;
