/**
 * @file   src\components\PaginationList.tsx
 * @brief  Product listing page
 * @date January, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationList = ({ ...props }) => {
  const [count, setCount] = useState(props.count ?? 0);
  const [active, setActive] = useState(props.active);
  const [pageNumbers, setPageNumbers] = useState<(JSX.Element | null)[]>([]);

  useEffect(() => {
    setCount(props.count);
  }, [props.count]);

  const onPageNumberClick = (number: number) => {
    setActive(number);
    props.setActive(number);
  };

  useEffect(() => {
    if (count > 0) {
      let isPageNumberOutOfRange: boolean;

      const pageNumberss = [...new Array(Math.ceil(count / props.limit))].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === Math.ceil(count / props.limit);
        const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - active) <= 2;

        if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithinTwoPageNumbers) {
          isPageNumberOutOfRange = false;
          return (
            <Pagination.Item key={pageNumber} active={pageNumber === props?.active} onClick={() => onPageNumberClick(pageNumber)}>
              {pageNumber}
            </Pagination.Item>
          );
        }

        if (!isPageNumberOutOfRange) {
          isPageNumberOutOfRange = true;
          return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }
        return null;
      });
      setPageNumbers(pageNumberss);
    }
  }, [count, active]);

  return (
    <Pagination className="justify-content-center">
      <Pagination.Prev
        disabled={props.active === 1}
        onClick={() => {
          setActive(props.active > 1 ? props.active - 1 : props.active);
          props.setActive(props.active > 1 ? props.active - 1 : props.active);
        }}
      />
      {pageNumbers}
      <Pagination.Next
        disabled={props.active === Math.ceil(count / props.limit)}
        onClick={() => {
          setActive(props.active === Math.ceil(count / props.limit) ? props.active : props.active + 1);
          props.setActive(props.active === Math.ceil(count / props.limit) ? props.active : props.active + 1);
        }}
      />
    </Pagination>
  );
};
export default PaginationList;
