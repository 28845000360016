const IcMenu = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_4073" data-name="Group 4073" transform="translate(13408.203 10010)">
        <rect id="Rectangle_860" data-name="Rectangle 860" width="28" height="28" transform="translate(-13408.203 -10010)" fill="none" stroke="none" />
        <g id="menu" transform="translate(-5494.311 615.5)">
          <line id="Line_16" data-name="Line 16" x2="21.595" transform="translate(-7910.689 -10619.5)" fill="none" strokeLinecap="round" strokeWidth="3" />
          <line id="Line_17" data-name="Line 17" x2="21.595" transform="translate(-7910.689 -10611.5)" fill="none" strokeLinecap="round" strokeWidth="3" />
          <line id="Line_18" data-name="Line 18" x2="21.595" transform="translate(-7910.689 -10603.5)" fill="none" strokeLinecap="round" strokeWidth="3" />
        </g>
      </g>
    </svg>
  );
};

export default IcMenu;
