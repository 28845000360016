const IcVideo = (props: any) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 32 32">
        <g id="Group_4050" data-name="Group 4050" transform="translate(-860 -354)">
          <rect id="Rectangle_849" data-name="Rectangle 849" width="32" height="32" rx="8" transform="translate(860 354)" fill="#e9ba4f" />
          <path
            id="Polygon_2"
            data-name="Polygon 2"
            d="M5.639,1.457a1,1,0,0,1,1.722,0l4.748,8.034A1,1,0,0,1,11.248,11h-9.5A1,1,0,0,1,.892,9.491Z"
            transform="translate(883 363) rotate(90)"
            fill="#fff"
          />
        </g>
      </svg>
    );
  };
  
  export default IcVideo;
  