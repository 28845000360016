/**
 * @file   src\containers\Login.tsx
 * @brief  Login.
 *@date November, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../assets/styles/prelogin.scss';
import { Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, KeyboardEvent } from 'react';
import { toast } from 'react-toastify';
import logo from '../assets/img/logo-prelogin.png';
import pussy from '../assets/img/pussy.png';
import dog from '../assets/img/dog.png';
import Strings from '../assets/strings/Strings.json';
import ErrorStrings from '../assets/strings/ErrorStrings.json';
import PAInput from '../components/PAInput';
import { AuthErrors, IAuthRequest } from '../interfaces/authInterface';
import { validationEmail, passwordValidation } from '../helpers/functions';
import IcVisible from '../assets/img/icons/Visible';
import IcHide from '../assets/img/icons/Hide';
import { login } from '../store/actions/authAction';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SetLoginData } from '../utils/localStorage';
import HomeLoader from '../components/loader/homeLoader';
import { changeLoaderStatus } from '../store/actions/generalAction';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const LoaderData = useAppSelector((RootReducer) => RootReducer.general.loaderState.loader);
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    pass: '',
  });
  const [errors, setErrors] = useState<AuthErrors>({});
  const [showPassword, setShowPassword] = useState(false);
  const validate = () => {
    let isValid = true;
    let focusSet = false;
    const errorss: AuthErrors = {};
    if (loginFormData?.email === '') {
      isValid = false;
      errorss.email = ErrorStrings.LoginValidation.EmptyEmail;
      if (!focusSet) {
        focusSet = true;
      }
    } else if (!validationEmail(loginFormData?.email)) {
      isValid = false;
      errorss.email = ErrorStrings.LoginValidation.ValidEmail;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (loginFormData?.pass === '') {
      isValid = false;
      errorss.pass = ErrorStrings.LoginValidation.EmptyPassword;
      if (!focusSet) {
        focusSet = true;
      }
    } else if (!passwordValidation(loginFormData?.pass)) {
      isValid = false;
      errorss.pass = ErrorStrings.LoginValidation.PasswordCriteria;
      if (!focusSet) {
        focusSet = true;
      }
    }
    setErrors(errorss);
    return isValid;
  };

  const handleChangeInputs = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setLoginFormData({ ...loginFormData, [name]: value });
  };

  const signIn = async () => {
    const isValid = validate();
    if (isValid) {
      dispatch(changeLoaderStatus(true) as any);
      try {
        const info: IAuthRequest = {
          email: loginFormData.email,
          pass: loginFormData.pass,
        };
        const { payload } = await dispatch(login(info) as any);
        if (payload.status === 200) {
          const authInfo = payload.data;
          SetLoginData(authInfo);
          navigate('/manageusers', { replace: true });
          window.location.reload();
          window.history.replaceState(null, '', '/manageusers');
        } else {
          toast.error(payload.data.error);
          dispatch(changeLoaderStatus(false) as any);
          navigate('/');
        }
      } catch (error) {
        return false;
      }
    }
    return undefined;
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      signIn();
    }
  };

  return (
    <Container fluid className="login_bg d-flex align-items-center justify-content-center">

      {LoaderData ? <HomeLoader /> :
        <>
          <div className="login text-center">
            <img src={logo} alt="logo" />
            <h1 className="mb-4">{Strings.Login.Title}</h1>
            <PAInput
              id="email"
              name="email"
              type="email"
              placeholder={Strings.Login.Placeholder1}
              value={loginFormData?.email ?? ''}
              onChange={handleChangeInputs}
              alert={errors?.email}
              onKeyDown={handleKeyPress}
            />
            <div className="eye">
              <Button tabIndex={-1} className="minWidth" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <IcVisible /> : <IcHide />}
              </Button>
              <PAInput
                id="pass"
                name="pass"
                type={showPassword ? 'text' : 'password'}
                placeholder={Strings.Login.Placeholder2}
                value={loginFormData?.pass ?? ''}
                onChange={handleChangeInputs}
                alert={errors?.pass}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="forgot text-end">
              <Link to="/ForgotPassword" className="forgotLink">
                {Strings.Login.link}
              </Link>
            </div>
            <Button size="lg" type="submit" onClick={signIn}>
              {Strings.Login.button}
            </Button>
          </div>
          <div className="pussy">
            <img src={pussy} alt="pussy_image" />
          </div>
          <div className="dog">
            <img src={dog} alt="dog_image" />
          </div>
        </>
      }
    </Container>
  );
};

export default Login;
