const IcAdvertisements = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_4070" data-name="Group 4070" transform="translate(-46.5 -239.798)">
        <rect id="Rectangle_857" data-name="Rectangle 857" width="28" height="28" transform="translate(46.5 239.798)" fill="none" stroke="none" />
        <g id="projector-screen--projector-screen-work-meeting-presentation" transform="translate(45.188 238.188)">
          <path
            id="Rectangle_57"
            data-name="Rectangle 57"
            d="M2.848,3.927a1.187,1.187,0,0,1,1.259-.98c1.464-.053,4.768-.135,11.205-.135s9.74.082,11.205.135a1.188,1.188,0,0,1,1.26.98,5.948,5.948,0,0,1,.035.671,5.789,5.789,0,0,1-.036.671,1.187,1.187,0,0,1-1.259.98c-1.464.053-4.768.135-11.205.135S5.572,6.3,4.107,6.249a1.188,1.188,0,0,1-1.26-.98A5.9,5.9,0,0,1,2.812,4.6,5.79,5.79,0,0,1,2.848,3.927Z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Rectangle_123"
            data-name="Rectangle 123"
            d="M4.712,33.057A1.228,1.228,0,0,1,6.012,32c1.4-.053,4.375-.129,9.985-.129s8.588.076,9.984.129a1.228,1.228,0,0,1,1.3,1.054c.015.173.025.373.025.6s-.01.43-.025.6a1.228,1.228,0,0,1-1.3,1.054c-1.4.053-4.375.129-9.984.129s-8.588-.076-9.984-.129a1.228,1.228,0,0,1-1.3-1.054c-.015-.173-.025-.373-.025-.6s.01-.43.025-.6Z"
            transform="translate(-0.685 -10.61)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Subtract"
            d="M6.563,8.278V15.82c0,3.543.093,6.065.173,7.567M26.8,8.279V15.82c0,3.543-.093,6.065-.173,7.567"
            transform="translate(-1.369 -1.995)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Vector_1132"
            data-name="Vector 1132"
            d="M22.5,37.5v3.571"
            transform="translate(-7.188 -12.664)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcAdvertisements;
