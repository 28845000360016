const IcView = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="ViewIcon" transform="translate(0 0.027)">
        <rect id="Rectangle_856" data-name="Rectangle 856" width="28" height="28" transform="translate(0 -0.027)" fill="none" />
        <g id="eye-optic--health-medical-eye-optic" transform="translate(3.373 -0.937)">
          <path
            id="Intersect"
            d="M2.557,15.253a1.281,1.281,0,0,1,0-.686,8.379,8.379,0,0,1,16.142,0,1.287,1.287,0,0,1,0,.686,8.379,8.379,0,0,1-16.142,0Z"
            transform="translate(0)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Ellipse_19"
            data-name="Ellipse 19"
            d="M20.739,18.807A3.807,3.807,0,1,1,16.932,15,3.807,3.807,0,0,1,20.739,18.807Z"
            transform="translate(-6.304 -3.898)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcView;
