const IcAdd = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.996" height="13.702" viewBox="0 0 13.996 13.702">
      <g id="Group_4073" data-name="Group 4073" transform="translate(2020 10402.071)">
        <rect id="Rectangle_858" data-name="Rectangle 858" width="13.996" height="13.702" transform="translate(-2020 -10402.071)" fill="none" />
        <path id="plus" d="M-42.323-.52l-.021-9.681,2.688.021L-39.635-.5Zm-3.633-3.57-.021-2.541L-36-6.61l.021,2.541Z" transform="translate(-1972.023 -10389.87)" fill="#fff" />
      </g>
    </svg>
  );
};

export default IcAdd;
