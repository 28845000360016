const IcArrowBack = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="Group_4080" data-name="Group 4080" transform="translate(6290 10671)">
        <rect id="Rectangle_867" data-name="Rectangle 867" width="12" height="12" transform="translate(-6290 -10671)" fill="#fff" opacity="0" />
        <g id="Group_4079" data-name="Group 4079" transform="translate(-6296.5 -10677.501)">
          <path
            id="Path_11643"
            data-name="Path 11643"
            d="M10.577,16.257a1.246,1.246,0,0,1-.884-.366L6.083,12.281a1.965,1.965,0,0,1,0-2.781l3.608-3.61a1.25,1.25,0,1,1,1.768,1.767L8.229,10.891l3.233,3.233a1.25,1.25,0,0,1-.884,2.134Z"
            transform="translate(1.604 1.61)"
            fill="#0b4aa5"
          />
          <path
            id="Line_3"
            data-name="Line 3"
            d="M8.069,1.25H0A1.25,1.25,0,0,1-1.25,0,1.25,1.25,0,0,1,0-1.25H8.069A1.25,1.25,0,0,1,9.319,0,1.25,1.25,0,0,1,8.069,1.25Z"
            transform="translate(8.57 12.501)"
            fill="#0b4aa5"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcArrowBack;
