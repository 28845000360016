const IcBell = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 112 112">
      <g id="Group_4069" data-name="Group 4069" transform="translate(-334 -85.995)">
        <g id="Group_3667" data-name="Group 3667" transform="translate(334 85.998)">
          <circle id="Ellipse_114" data-name="Ellipse 114" cx="56" cy="56" r="56" transform="translate(0 -0.003)" fill="#fff" />
        </g>
        <g id="ringing-bell-notification--notification-vibrate-ring-sound-alarm-alert-bell-noise" transform="translate(367.188 121.184)">
          <path id="Subtract" d="M15.045,35.511q3.726.12,7.455.114c2.859,0,5.328-.042,7.456-.114a7.5,7.5,0,0,1-14.911,0Z" fill="#fe3b00" />
          <path
            id="Union"
            d="M36.745,15.89a14.3,14.3,0,0,0-28.492,0L7.8,21.13a15,15,0,0,1-2.181,6.6L4.246,29.962c-1.386,2.243-.63,4.562,1.984,4.91a129.673,129.673,0,0,0,16.27.754,129.683,129.683,0,0,0,16.27-.754c2.614-.348,3.369-2.666,1.984-4.91l-1.378-2.235a15,15,0,0,1-2.182-6.6l-.449-5.241Z"
            fill="#ffc08f"
          />
          <path
            id="Union_2"
            d="M36.746,15.89a14.3,14.3,0,0,0-28.493,0L7.8,21.13a15,15,0,0,1-2.18,6.6l-1.38,2.235C2.86,32.2,3.616,34.522,6.23,34.87a129.415,129.415,0,0,0,16.27.755,129.672,129.672,0,0,0,16.27-.754c2.614-.348,3.369-2.666,1.985-4.91l-1.38-2.235a15,15,0,0,1-2.18-6.6l-.45-5.241Z"
            fill="none"
          />
          <path
            id="Vector_1499"
            data-name="Vector 1499"
            d="M2.827,15.938A16.843,16.843,0,0,1,9.375,2.813"
            fill="none"
            stroke="#eb7948"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector_1500"
            data-name="Vector 1500"
            d="M42.173,15.938A16.843,16.843,0,0,0,35.625,2.813"
            fill="none"
            stroke="#eb7948"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcBell;
