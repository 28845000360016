/**
 *@file PAHistory.tsx
 *@brief Reusable select component
 *@date November, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import './PAHistory.scss';

const PAHistory = ({ title, time, user }: any) => {
  return (
    <div className="history-list">
      <h3>{title}</h3>
      <div className="d-flex justify-content-between align-items-center">
        <label htmlFor="time">{time}</label>
        <span>{user}</span>
      </div>
    </div>
  );
};

export default PAHistory;
