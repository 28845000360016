/**
 *@file PARadio.tsx
 *@brief Reusable input component
 *@date December, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */

import { Form } from 'react-bootstrap';
import './PARadio.scss';

const PARadio = ({ type, label, name, value, onChange, checked }: any) => {
  return <Form.Check className="pa-radio" type={type} label={label} name={name} value={value} onChange={onChange} checked={checked} />;
};

export default PARadio;
