// import React, { Component, useEffect } from 'react';
// import loader from '../../assets/img/loader.gif';
// import './loader.css';

// class HomeLoader extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             value: 0,
//             objectID: 0
//         };
//     }

//     componentDidMount() {
//     }

//     render() {
//         return (
//             <div id="home_page_loader">
//                 <img src={loader}></img>
//             </div>
//         );
//     }
// }

// export default HomeLoader;

/**
 * @file   src\components\Header.tsx
 * @brief  Header component.
 * @date November, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import loader from '../../assets/img/loader.gif';
import './loader.css';

const HomeLoader = () => {
  return (
<div id="home_page_loader">
                <img src={loader}></img>
            </div>  );
};

export default HomeLoader;
