const IcSuccessModal = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112" viewBox="0 0 112 112">
      <g id="Group_3667" data-name="Group 3667" transform="translate(0 0.003)">
        <circle id="Ellipse_114" data-name="Ellipse 114" cx="56" cy="56" r="56" transform="translate(0 -0.003)" fill="#fff" />
        <g id="Group_3676" data-name="Group 3676" transform="translate(-703.785 -376.003)">
          <rect id="Rectangle_534" data-name="Rectangle 534" width="47.213" height="36.885" transform="translate(736.108 409.974)" fill="#fff" />
          <g id="projector-screen--projector-screen-work-meeting-presentation" transform="translate(730.785 402)">
            <rect id="Rectangle_853" data-name="Rectangle 853" width="48" height="39" transform="translate(4.938 6.592)" fill="#e3d1ff" />
            <path
              id="Rectangle_57"
              data-name="Rectangle 57"
              d="M2.9,5.389A2.745,2.745,0,0,1,5.806,3.124C9.192,3,16.831,2.812,31.717,2.812S54.24,3,57.627,3.124A2.747,2.747,0,0,1,60.54,5.389a13.754,13.754,0,0,1,.081,1.553,13.387,13.387,0,0,1-.083,1.553,2.745,2.745,0,0,1-2.911,2.266c-3.386.122-11.025.311-25.91.311S9.193,10.882,5.806,10.76A2.747,2.747,0,0,1,2.894,8.494a13.652,13.652,0,0,1-.081-1.553A13.388,13.388,0,0,1,2.9,5.389Z"
              transform="translate(-2.812 -2.812)"
              fill="#a45bf8"
            />
            <rect id="Rectangle_854" data-name="Rectangle 854" width="6" height="17" rx="3" transform="translate(24.938 46.592)" fill="#5a3693" />
            <path
              id="Rectangle_123"
              data-name="Rectangle 123"
              d="M4.745,34.609a2.84,2.84,0,0,1,3.006-2.436c3.229-.122,10.116-.3,23.088-.3s19.858.175,23.088.3a2.84,2.84,0,0,1,3.006,2.436c.036.4.058.863.058,1.4s-.022,1-.058,1.4a2.84,2.84,0,0,1-3.006,2.436c-3.229.122-10.116.3-23.088.3s-19.858-.175-23.088-.3A2.84,2.84,0,0,1,4.745,37.4c-.036-.4-.058-.863-.058-1.4s.022-1,.058-1.4Z"
              transform="translate(-1.935 10.793)"
              fill="#a45bf8"
            />
            <path id="Vector_1132" data-name="Vector 1132" d="M22.5,37.5v8.258" transform="translate(6.404 13.426)" fill="none" />
            <g id="Group_3677" data-name="Group 3677" transform="translate(16.38 17.038)">
              <rect id="Rectangle_535" data-name="Rectangle 535" width="25" height="17" rx="1" transform="translate(-0.442 -0.445)" fill="#5a3693" />
              <path id="Polygon_1" data-name="Polygon 1" d="M4,0,8,5H0Z" transform="translate(14.558 4.555) rotate(90)" fill="#fff" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IcSuccessModal;
