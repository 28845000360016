/**
 * @file   src\containers\ChangePassword.tsx
 * @brief  Product listing page
 * @date December, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, KeyboardEvent } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Strings from '../assets/strings/Strings.json';
import PAInput from '../components/PAInput';
import IcVisible from '../assets/img/icons/Visible';
import IcHide from '../assets/img/icons/Hide';
import { NewPasswordErrors } from '../interfaces/authInterface';
import ErrorStrings from '../assets/strings/ErrorStrings.json';
import { passwordMatchValidation, passwordValidation } from '../helpers/functions';
import { resetPassword } from '../store/actions/authAction';
import { useAppDispatch } from '../hooks';

const AddAdvertisement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [formdata, setFormdata] = useState({
    repassword: '',
    newpassword: '',
    oldpassword: '',
  });
  const [errors, setErrors] = useState<NewPasswordErrors>({});
  const validate = () => {
    let isValid = true;
    let focusSet = false;
    const errorss: NewPasswordErrors = {};
    if (formdata?.oldpassword === '') {
      isValid = false;
      errorss.oldpassword = ErrorStrings.NewPassword.EmptyOldPassword;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (formdata?.newpassword === '') {
      isValid = false;
      errorss.newpassword = ErrorStrings.NewPassword.EmptyPassword;
      if (!focusSet) {
        focusSet = true;
      }
    } else if (!passwordValidation(formdata?.newpassword)) {
      isValid = false;
      errorss.newpassword = ErrorStrings.LoginValidation.PasswordCriteria;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (!passwordMatchValidation(formdata?.newpassword, formdata?.repassword)) {
      isValid = false;
      errorss.repassword = ErrorStrings.NewPassword.PasswordMismatch;
      focusSet = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const handleChangeInputs = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormdata({ ...formdata, [name]: value });
  };

  const saveSubmit = async () => {
    const isValid = validate();
    if (isValid) {
      const funReq = {
        newpwd: formdata.newpassword,
        oldpwd: formdata.oldpassword,
      };
      try {
        const { payload } = await dispatch(resetPassword(funReq) as any);
        if (payload.status === 200) {
          toast.success(payload.data.msg);
          setFormdata({
            ...formdata,
            newpassword: '',
            repassword: '',
            oldpassword: '',
          });
          navigate('/manageusers');
        } else {
          toast.error(payload.error);
        }
      } catch (error) {
        return false;
      }
    }
    return undefined;
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      saveSubmit();
    }
  };

  const HandleCancel = () => {
    navigate('/manageusers');
  };


  return (
    <div className="layout-content">
      <Container fluid className="content-wrap changepass Form-cstm">
        <div className="sub-head">
          <Row className="align-items-center header mb-4">
            <Col className="heading d-flex">
              <h2>{Strings.ChangePassword.heading}</h2>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={12} xl={5}>
            <div className="mt-4">
              <div className="eye">
                <Button tabIndex={-1} onClick={() => setShowOldPassword(!showOldPassword)}>
                  {showOldPassword ? <IcVisible /> : <IcHide />}
                </Button>
                <PAInput
                  id="oldpassword"
                  name="oldpassword"
                  type={showOldPassword ? 'text' : 'password'}
                  placeholder={Strings.ChangePassword.oldpass}
                  value={formdata?.oldpassword ?? ''}
                  onChange={handleChangeInputs}
                  alert={errors?.oldpassword}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <h3>{Strings.ChangePassword.subheading}</h3>
              <p>Choose a strong password.</p>
              <div className="mt-5">
                <div className="eye">
                  <Button tabIndex={-1} onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <IcVisible /> : <IcHide />}
                  </Button>
                  <PAInput
                    id="newpassword"
                    name="newpassword"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={Strings.ChangePassword.newpass}
                    value={formdata?.newpassword ?? ''}
                    onChange={handleChangeInputs}
                    alert={errors?.newpassword}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="eye">
                  <Button tabIndex={-1} onClick={() => setShowRePassword(!showRePassword)}>
                    {showRePassword ? <IcVisible /> : <IcHide />}
                  </Button>
                  <PAInput
                    id="newrepassword"
                    name="repassword"
                    type={showRePassword ? 'text' : 'password'}
                    placeholder={Strings.ChangePassword.repass}
                    value={formdata?.repassword ?? ''}
                    onChange={handleChangeInputs}
                    alert={errors?.repassword}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="buttons d-flex justify-content-between">
                  <Button size="lg" variant="secondary" onClick={HandleCancel}>
                    Cancel
                  </Button>
                  <Button size="lg" variant="primary" onClick={saveSubmit}>
                    Done
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddAdvertisement;
