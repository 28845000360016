/**
 * @file   src\containers\UserDetails.tsx
 * @brief  Product listing page
 * @date December, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Strings from '../assets/strings/Strings.json';
import IcArrowBack from '../assets/img/icons/ArrowBack';
import PAHistory from '../components/PAHistory';
import { useAppDispatch, useAppSelector } from '../hooks';
import { userDetails } from '../store/actions/userAction';
import { changeLoaderStatus } from '../store/actions/generalAction';
import HomeLoader from '../components/loader/homeLoader';

const UserDetails = () => {
  const LoaderData = useAppSelector((RootReducer) => RootReducer.general.loaderState.loader);
  const moment = require('moment');
  const location = useLocation();
  const userId = location.state?.userId || 0;
  const dispatch = useAppDispatch();

  const [userdata, setUserdata] = useState<any>();

  const GetUserDetails = async () => {
    const funReq = {
      userid: userId,
    };
    try {
      const { payload } = await dispatch(userDetails(funReq) as any);
      if (payload.status === 200) {
        const userData = payload.data.userdetails;
        if (userData) {
          if (userData.wellnessdata?.alertfrequency) {
            let alertHour;
            if (userData.wellnessdata?.alertfrequency > 3600) {
              alertHour = userData.wellnessdata?.alertfrequency / 3600 + " Hours";
            }
            if (userData.wellnessdata?.alertfrequency > 60 && userData.wellnessdata?.alertfrequency <= 3600) {
              alertHour = userData.wellnessdata?.alertfrequency / 60 + " Minutes";
            }
            if (userData.wellnessdata?.alertfrequency < 60) {
              alertHour = userData.wellnessdata?.alertfrequency + " Seconds";
            }
            if (alertHour === (24 + " Hours")) {
              userData.wellnessdata.alertfrequency = "Every " + alertHour + (" (Default)");
            }
            else {
              userData.wellnessdata.alertfrequency = "Every " + alertHour;
            }
          }
          if (userData.wellnessdata?.delaytonotify) {
            let delay;
            if (userData.wellnessdata?.delaytonotify > 60 && userData.wellnessdata?.delaytonotify <= 3600) {
              delay = userData.wellnessdata?.delaytonotify / 60 + " Minutes"
            }
            if (userData.wellnessdata?.delaytonotify > 3600) {
              delay = userData.wellnessdata?.delaytonotify / 3600 + " Hours"
            }
            if (userData.wellnessdata?.delaytonotify < 60) {
              delay = userData.wellnessdata?.delaytonotify + " Seconds"
            }
            if (delay === (60 + " Minutes")) {
              userData.wellnessdata.delaytonotify = 1 + " Hour (Default)";
            }
            else {
              userData.wellnessdata.delaytonotify = delay;
            }
          }
          if (userData.wellnessdata?.alerttime) {
            const date = new Date(userData.wellnessdata?.alerttime * 1000);
            const timestamp = date.toUTCString();
            const date1 = new Date(timestamp);
            let hours = date1.getHours();
            let minutes = date1.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            let minutes1 = minutes < 10 ? '0' + minutes : minutes;
            const strTime = hours + ':' + minutes1 + ' ' + ampm;
            userData.wellnessdata.alerttime = strTime;
          }
          if (userData.wellnessdata?.notifypddelay) {
            let delay;
            if (userData.wellnessdata?.notifypddelay > 60 && userData.wellnessdata?.notifypddelay <= 3600) {
              delay = userData.wellnessdata?.notifypddelay / 60 + " Minutes"
            }
            else if (userData.wellnessdata?.notifypddelay > 3600) {
              delay = userData.wellnessdata?.notifypddelay / 3600 + " Hours"
            }
            if (userData.wellnessdata?.notifypddelay < 60) {
              delay = userData.wellnessdata?.notifypddelay + " Seconds"
            }
            userData.wellnessdata.notifypddelay = delay;
          }
        }
        setUserdata(userData);
      } else {
        toast.error(payload.data.error);
      }
    } catch (error) {
      return false;
    }
    dispatch(changeLoaderStatus(false) as any);
    return undefined;
  };
  useEffect(() => {
    GetUserDetails();
  }, []);

  const navigate = useNavigate();
  const moveBack = () => {
    dispatch(changeLoaderStatus(true) as any);
    navigate('/manageusers');
  };

  return (
    <>
      {LoaderData ? <HomeLoader /> :
        <div className="layout-content">
          <Container fluid className="content-wrap">
            <div className="sub-head">
              <Row className="align-items-center header mb-4">
                <Col className="heading d-flex">
                  <Button onClick={moveBack}>
                    <IcArrowBack />
                  </Button>
                  <h2>{Strings.UserDetails.heading}</h2>
                </Col>
              </Row>
            </div>
            <div className="sub-head1">
              <h3 className="mb-4">{Strings.UserDetails.subheading}</h3>
            </div>
            <Row>
              <Col lg={7} xl={8} className="pb-4">
                <div className="user-info">
                  {userdata?.purchasedata && userdata?.purchasedata?.isexpired == false && userdata?.purchasedata?.productname != "" &&
                    <div className="plan-wrap">
                      <span className="plan">{userdata?.purchasedata?.productname}</span>
                    </div>
                  }
                  <div className="d-flex justify-content-center">
                    <div className="user-pic">
                      <img src={userdata?.profileimage} alt={userdata?.profileimage ? 'profImg' : undefined} />
                    </div>
                    <div className="details">
                      <div className="list">
                        <label htmlFor="name">
                          <h2>{userdata?.name}</h2>
                        </label>
                        <label htmlFor="phone">{userdata?.phone}</label>
                        <label htmlFor="email">{userdata?.email}</label>
                        <label htmlFor="createdAt">
                          Registered Date: <span>{userdata ? moment.utc(userdata.CreatedAt).local().format('MM-DD-YYYY') : null}</span>
                        </label>
                        <label htmlFor="createdAt">
                          Alert Status: <span>{userdata?.wellnessdata?.alertoverrideid == 1
                            ? "Enabled"
                            : userdata?.wellnessdata?.alertoverrideid == 2
                              ? "Vacation Mode"
                              : userdata?.wellnessdata?.alertoverrideid == 3
                                ? "Stopped"
                                : null}</span>
                        </label>
                        <label htmlFor="createdAt">
                          Notify LPD: <span>{userdata?.wellnessdata?.notifypd == true ? "On" : "Off"}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={5} xl={4} className="pb-4">
                <div className="history">
                  <h2>{Strings.UserDetails.UpdateHistory}</h2>
                  <div className="list-wrap">
                    {userdata && userdata.wellnessdata?.updatehistory !== undefined && userdata.wellnessdata?.updatehistory.length > 0
                      ? userdata.wellnessdata.updatehistory.map((data: any) => (
                        <PAHistory
                          key={data.id}
                          title={`${data.wellnessdatatypeid === 2 ? 'Updated Alert Status' : 'Updated Notify LPD'}`}
                          time={moment.utc(data.CreatedAt).local().format('MMM DD, h:mm a')}
                          user={`${data.updatedusertypeid === 1 ? 'Admin' : 'User'}`}
                        />
                      ))
                      : "No Data"}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="pb-4">
                <div className="contact orange ">
                  <h3>{Strings.UserDetails.ICEInformation}</h3>
                  <div className="d-flex justify-content-between align-items-center">
                    {userdata?.wellnessdata !== null ? userdata?.wellnessdata?.icename == "" && userdata?.wellnessdata?.icenumber == "" ?
                      <label htmlFor="userName">{"ICE Approval is Pending"}</label>
                      :
                      <>
                        <Col>
                          <label htmlFor="username">{Strings.UserDetails.name}</label>
                          <span>{userdata?.wellnessdata?.icename}</span>
                        </Col>
                        <Col className="text-lg-end">
                          <label htmlFor="userphone">{Strings.UserDetails.phone}</label>
                          <span>{userdata?.wellnessdata?.icenumber}</span>
                        </Col>
                      </>
                      : <label htmlFor="userName">{"ICE Approval is Pending."}</label>}
                  </div>
                </div>
              </Col>
              <Col lg={6} className="pb-4">
                <div className="contact brown">
                  <h3>{Strings.UserDetails.LocalPolice}</h3>
                  <div className="d-flex justify-content-between align-items-center">
                    {userdata?.wellnessdata !== null ? userdata?.wellnessdata?.pdcontact == "" && userdata?.wellnessdata?.pdnumber == "" ?
                      <label htmlFor="userName">{"No LPD Details Added."}</label>
                      :
                      <>
                        <Col>
                          <label htmlFor="userName">{Strings.UserDetails.name}</label>
                          <span>{userdata?.wellnessdata?.pdcontact}</span>
                        </Col>
                        <Col className="text-lg-end">
                          <label htmlFor="userPhone">{Strings.UserDetails.phone}</label>
                          <span>{userdata?.wellnessdata?.pdnumber}</span>
                        </Col>
                      </>
                      : <label htmlFor="userName">{"No LPD Details Added."}</label>}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="info">
              <Col lg={4} md={12}>
                <h2>{Strings.UserDetails.localinfo}</h2>
                <div className="locationinfo">
                  <label htmlFor="address1">{userdata?.address1}</label>
                  <label htmlFor="address1">{userdata?.address2}</label>
                  <label htmlFor="city">{userdata?.city}</label>
                  <label htmlFor="address1">{userdata?.state}</label>
                  <label htmlFor="country">{userdata?.country}</label>
                  <label htmlFor="zip">{userdata?.zip}</label>
                </div>
              </Col>
              <Col lg={8} md={12}>
                <h2>{Strings.UserDetails.AlertInfo}</h2>
                <Row className="g-3">
                  <Col lg={6}>
                    <div className="alertinfo">
                      <p>Timed Space (Hours Between Wellness Check Alerts) </p>
                      <span>{userdata?.wellnessdata ? <span>{userdata?.wellnessdata?.alertfrequency}</span> : null}</span>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="alertinfo">
                      <p>Time Interval to notify ICE Contact in Case of Non-Response After Wellness Check Alert</p>
                      <span>{userdata?.wellnessdata ? <span>{userdata?.wellnessdata?.delaytonotify}</span> : null}</span>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="alertinfo">
                      <p>Time of Day for Wellness Check Alerts </p>
                      <span>{userdata?.wellnessdata ? <span>{userdata?.wellnessdata?.alerttime}</span> : null}</span>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="alertinfo">
                      <p>Time Interval to notify Local Police Department in Case of Non-Response After Wellness Check Alert</p>
                      <span>{userdata?.wellnessdata ? <span>{userdata?.wellnessdata?.notifypddelay}</span> : null}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      }</>
  );
};

export default UserDetails;
