/**
 * @file   src\containers\Login.tsx
 * @brief  Home page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, KeyboardEvent } from 'react';
import '../assets/styles/prelogin.scss';
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/logo-prelogin.png';
import Strings from '../assets/strings/Strings.json';
import PAInput from '../components/PAInput';
import { NewPasswordErrors } from '../interfaces/authInterface';
import ErrorStrings from '../assets/strings/ErrorStrings.json';
import { passwordMatchValidation, passwordValidation } from '../helpers/functions';
import IcVisible from '../assets/img/icons/Visible';
import IcHide from '../assets/img/icons/Hide';
import { useAppDispatch } from '../hooks';
import { newpwd } from '../store/actions/authAction';
import { GetForgotPasswordEmail } from '../utils/localStorage';
import { useLocation } from 'react-router-dom';


const NewPassword = () => {
  const search = useLocation().search
  let t = new URLSearchParams(search).get("t")
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const localEmail = GetForgotPasswordEmail();
  const [otp, setOtp] = useState('');
  const [formdata, setFormdata] = useState({
    repassword: '',
    newpassword: '',
    otp: '',
  });
  const [errors, setErrors] = useState<NewPasswordErrors>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const validate = () => {
    let isValid = true;
    let focusSet = false;
    const errorss: NewPasswordErrors = {};
    if (formdata?.newpassword === '') {
      isValid = false;
      errorss.newpassword = ErrorStrings.NewPassword.EmptyPassword;
      if (!focusSet) {
        focusSet = true;
      }
    } else if (!passwordValidation(formdata?.newpassword)) {
      isValid = false;
      errorss.newpassword = ErrorStrings.LoginValidation.PasswordCriteria;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (!passwordMatchValidation(formdata?.newpassword, formdata?.repassword)) {
      isValid = false;
      errorss.repassword = ErrorStrings.NewPassword.PasswordMismatch;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (otp === '') {
      isValid = false;
      errorss.otp = ErrorStrings.NewPassword.EmptyOTP;
      if (!focusSet) {
        focusSet = true;
      }
    }
    setErrors(errorss);
    return isValid;
  };

  const handleChangeInputs = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormdata({ ...formdata, [name]: value });
  };

  const signIn = async () => {
    const isValid = validate();
    if (isValid) {
      try {
        const info = {
          newpwd: formdata.newpassword,
          otp,
          t,
        };
        console.log("payload", info)
        const { payload } = await dispatch(newpwd(info) as any);
        if (payload.status === 200) {
          const authInfo = payload.data;
          toast.success(authInfo.msg);
          navigate('/');
        } else {
          toast.error(payload.error);
        }
      } catch (error) {
        return false;
      }
    }
    return undefined;
  };

  const handleOtp = (otpval: string) => {
    setOtp(otpval);
    setErrors({ ...errors, otp: '' });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      signIn();
    }
  };

  return (
    <Container fluid className="login_bg">
      <Container className="h-100">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col lg={6} className="d-flex align-items-center justify-content-center">
            <img className="logo" src={logo} alt="logo" />
          </Col>
          <Col lg={6}>
            <div className="login w-100">
              <h1>{Strings.NewPassword.Title}</h1>
              <p className="mb-5">{Strings.NewPassword.Paragraph}</p>
              <div className="mt-20">
                <div className="eye">
                  <Button tabIndex={-1} className="minWidth" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <IcVisible /> : <IcHide />}
                  </Button>
                  <PAInput
                    id="password"
                    name="newpassword"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={Strings.NewPassword.Placeholder1}
                    value={formdata?.newpassword ?? ''}
                    onChange={handleChangeInputs}
                    alert={errors?.newpassword}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className="eye">
                  <Button tabIndex={-1} className="minWidth" onClick={() => setShowRePassword(!showRePassword)}>
                    {showRePassword ? <IcVisible /> : <IcHide />}
                  </Button>
                  <PAInput
                    id="repassword"
                    name="repassword"
                    type={showRePassword ? 'text' : 'password'}
                    placeholder={Strings.NewPassword.Placeholder2}
                    value={formdata?.repassword ?? ''}
                    onChange={handleChangeInputs}
                    alert={errors?.repassword}
                    onKeyDown={handleKeyPress}
                  />
                </div>
              </div>
              <div className="otp text-center">
                <label htmlFor="newPassword" className="label-head">
                  {Strings.NewPassword.label}
                </label>
                <div className="otp-input">
                  <OtpInput
                    name="otp"
                    value={otp}
                    onChange={handleOtp}
                    numInputs={6}
                    renderSeparator={false}
                    renderInput={(props) => <input {...props} />}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <Form.Control.Feedback style={{ display: 'block' }} type="invalid">
                  {errors?.otp}
                </Form.Control.Feedback>
              </div>
              <Button className="mt-4" size="lg" type="submit" onClick={signIn}>
                {Strings.NewPassword.button}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default NewPassword;
