const IcUsermanagement = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_4069" data-name="Group 4069" transform="translate(-45.5 -158.5)">
        <rect id="Rectangle_856" data-name="Rectangle 856" width="28" height="28" transform="translate(45.5 158.5)" fill="none" stroke="none" />
        <g id="user-multiple-accounts" transform="translate(44.188 157.188)">
          <path
            id="Rectangle_1096"
            data-name="Rectangle 1096"
            d="M11.744,5.193a2.941,2.941,0,0,1,2.663-2.168c1.521-.11,3.771-.213,6.845-.213,3.055,0,5.3.1,6.817.211a2.977,2.977,0,0,1,2.794,2.794c.109,1.521.211,3.762.211,6.817,0,3.074-.1,5.324-.212,6.845a2.941,2.941,0,0,1-2.168,2.663"
            transform="translate(-3.261)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Rectangle_1097"
            data-name="Rectangle 1097"
            d="M22.245,14.254a2.978,2.978,0,0,0-2.794-2.793c-1.521-.11-3.762-.211-6.817-.211s-5.3.1-6.817.211a2.977,2.977,0,0,0-2.794,2.794c-.11,1.52-.211,3.761-.211,6.817s.1,5.3.211,6.817a2.977,2.977,0,0,0,2.794,2.793c1.521.109,3.762.211,6.817.211s5.3-.1,6.817-.211a2.977,2.977,0,0,0,2.794-2.794c.109-1.521.211-3.762.211-6.817s-.1-5.3-.211-6.817Z"
            transform="translate(0 -3.08)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Union"
            d="M17.8,24.451a4.167,4.167,0,1,0-4.8-.006A5.783,5.783,0,0,0,9.73,27.373a1.3,1.3,0,0,0,1.014,1.893l.026,0q2.311.113,4.625.107c1.97,0,3.5-.048,4.625-.107l.027,0a1.3,1.3,0,0,0,1.014-1.893A5.782,5.782,0,0,0,17.8,24.451Z"
            transform="translate(-2.477 -5.134)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcUsermanagement;
