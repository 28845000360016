const IcSearch = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="Group_4069" data-name="Group 4069" transform="translate(-849.127 -153.372)">
        <rect id="Rectangle_856" data-name="Rectangle 856" width="18" height="18" transform="translate(849.127 153.372)" fill="none" />
        <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(851 155.245)" opacity="0.59">
          <path
            id="Path_11641"
            data-name="Path 11641"
            d="M17.171,10.835A6.335,6.335,0,1,1,10.835,4.5,6.335,6.335,0,0,1,17.171,10.835Z"
            transform="translate(-4.5 -4.5)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_11642"
            data-name="Path 11642"
            d="M28.42,28.42l-3.445-3.445"
            transform="translate(-14.165 -14.165)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcSearch;
