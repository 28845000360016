// Auth api urls.
export enum AuthApi {
  LOGIN = '/login',
  CHANGE_PASSWORD = '/resetpwd',
  FORGOT_PASSWORD = 'forgotpwd',
  NEW_PASSWORD = 'newpwd',
  GET_REFRESH_TOKEN = 'refreshtoken'
}
export enum UserApi {
  USER_LIST = 'userlist',
  UPDATE_ALERT_STATUS = 'updatewellnessdata',
  USER_DETAILS = 'userdetails',
  USER_DELETE = 'deleteuser',
}
export enum AdvertisementApi {
  ADVERTISEMENT_LIST = 'advertisementlist',
  ADVERTISEMENT_PUT_PRESIGNED = 'putpresigned',
  ADVERTISEMENT_ADD_EDIT_AD = 'createorupdateadvertisement',
  ADVERTISEMENT_EDIT_AD_STATUS = 'updateadstatus',
  GET_SAVED_S3_AD_FILE = 'getpresigned',
}
