/**
 * @file   src\components\Sidebar.tsx
 * @brief  SideMenu component.
 * @date November, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Strings from '../assets/strings/Strings.json';
import '../assets/styles/sidebar.scss';
import IcUsermanagement from '../assets/img/icons/UserManagement';
import IcAdvertisements from '../assets/img/icons/Advertisements';
import IcChangePassword from '../assets/img/icons/ChangePassword';
import IcLogout from '../assets/img/icons/Logout';
import IcConfirmModal from '../assets/img/icons/ConfirmModel';
import { ClearLocalStorage } from '../utils/localStorage';

const Sidebar = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    ClearLocalStorage();
    handleClose();
    navigate('/', { replace: true });
  };
  const handleClick = (e: any, url: any) => {
    e.preventDefault();
    window.location.href = url;
  };

  return (
    <div className="side-nav">
      <div className="navs justify-content-between">
        <div className="sec1">
          <NavLink
            onClick={(e) => handleClick(e, '/manageusers')}
            to="javascript:void(0)"
            className={(window.location.href.includes('/manageusers') || window.location.href.includes('/userdetails')) && show === false ? 'active' : undefined}
          >
            <IcUsermanagement />
            {Strings.MenuList.Menu1}
          </NavLink>
          <NavLink
            onClick={(e) => handleClick(e, '/advertisementlist')}
            to="javascript:void(0)"
            className={(window.location.href.includes('/advertisementlist') || window.location.href.includes('/AddAdvertisement')) && show === false ? 'active' : undefined}
          >
            <IcAdvertisements />
            {Strings.MenuList.Menu2}
          </NavLink>
        </div>
        <div className="sec2">
          <NavLink
            to="javascript:void(0)"
            className={window.location.href.includes('/changepassword') && show === false ? 'active' : undefined}
            onClick={(e) => handleClick(e, '/changepassword')}
          >
            <IcChangePassword />
            {Strings.MenuList.Menu3}
          </NavLink>
          <NavLink to="javascript:void(0)" className={show ? 'active mb-0' : 'mb-0'} onClick={handleShow}>
            <IcLogout />
            {Strings.MenuList.Menu4}
          </NavLink>
        </div>
      </div>
      <Modal size="lg" className="cstmmodel" show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="icon d-flex justify-content-center">
            <IcConfirmModal />{' '}
          </div>
          <h1 className="text-center">{Strings.LogoutConfirm.heading}</h1>
          <p className="text-center">{Strings.LogoutConfirm.Paragraph}</p>
          <div className="buttons mt-4 text-center">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleLogout}>
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Sidebar;
