const IcChangePassword = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_4072" data-name="Group 4072" transform="translate(-46 -819)">
        <rect id="Rectangle_859" data-name="Rectangle 859" width="28" height="28" transform="translate(46 819)" fill="none" stroke="none" />
        <g id="password-lock--circle-dots-lock-login-padlock-password-secure-security-textbox-type" transform="translate(44.188 817.188)">
          <path
            id="Rectangle_723"
            data-name="Rectangle 723"
            d="M27.47,14.574c.062-1.005.1-2.233.1-3.714A51.7,51.7,0,0,0,27.335,5.5a2.686,2.686,0,0,0-2.549-2.425c-1.749-.13-4.731-.263-9.593-.263s-7.844.134-9.593.263A2.686,2.686,0,0,0,3.051,5.5a51.685,51.685,0,0,0-.239,5.359,51.7,51.7,0,0,0,.239,5.359A2.686,2.686,0,0,0,5.6,18.644c1.749.13,4.731.263,9.593.263.886,0,1.71,0,2.476-.012"
            fill="none"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Vector"
            d="M26.289,32.937a1.95,1.95,0,0,1,1.9-1.906c.758-.052,1.829-.093,3.322-.093,1.58,0,2.682.046,3.438.1a1.819,1.819,0,0,1,1.767,1.733c.033.477.057,1.091.057,1.878q0,.939-.057,1.878a1.818,1.818,0,0,1-1.767,1.733c-.755.057-1.857.1-3.438.1s-2.682-.046-3.438-.1a1.819,1.819,0,0,1-1.767-1.733q-.061-.938-.057-1.878c0-.7.016-1.264.04-1.715Z"
            transform="translate(-7.961 -9.554)"
            fill="none"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Vector_2"
            d="M34.219,35.625v1.238"
            transform="translate(-10.668 -11.146)"
            fill="none"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Vector_3"
            d="M35.571,28.391l-.113-2.4a2.676,2.676,0,0,0-5.346,0L30,28.391"
            transform="translate(-9.235 -7.008)"
            fill="none"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Vector_4"
            d="M8.438,14.045a1.857,1.857,0,1,0,1.857-1.857A1.857,1.857,0,0,0,8.438,14.045Z"
            transform="translate(-1.911 -3.185)"
            fill="none"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Vector_5"
            d="M18.75,14.045a1.857,1.857,0,1,0,1.857-1.857A1.857,1.857,0,0,0,18.75,14.045Z"
            transform="translate(-5.414 -3.185)"
            fill="none"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Vector_6"
            d="M29.063,14.045a1.857,1.857,0,1,0,1.857-1.857A1.857,1.857,0,0,0,29.063,14.045Z"
            transform="translate(-8.917 -3.185)"
            fill="none"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcChangePassword;
