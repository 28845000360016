/**
 * @file   src\containers\AddAdvertisement.tsx
 * @brief  add advertisement page
 * @date January, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useRef, KeyboardEvent } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Strings from '../assets/strings/Strings.json';
import IcArrowBack from '../assets/img/icons/ArrowBack';
import PAInput from '../components/PAInput';
import PARadio from '../components/PARadio';
import PATextarea from '../components/PATextarea';
import IcSuccessModal from '../assets/img/icons/SuccessModel';
import IcInputAdd from '../assets/img/icons/PlusInput';
import IcInputDelete from '../assets/img/icons/DeleteInput';
import { AddAdvertisementErrors } from '../interfaces/advertisement';
import ErrorStrings from '../assets/strings/ErrorStrings.json';
import { useAppDispatch } from '../hooks';
import { adCreation, getpresigned, putpresigned, uploadTos3 } from '../store/actions/advertisementAction';
import { changeLoaderStatus } from '../store/actions/generalAction';
import { toast } from 'react-toastify';
import IcVideo from '../assets/img/icons/AdVideo';
import IcImage from '../assets/img/icons/AdImage';
import { Link } from 'react-router-dom';



const AddAdvertisement = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const adToedit = location.state?.data || null;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [uploadedFileName, setUploadedFileName] = useState<string | null>(adToedit ? adToedit.filename : null);
  const [choosedFile, setChoosedFile] = useState<string | null>(adToedit ? adToedit.filekey : null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<AddAdvertisementErrors>({});
  const [s3FileName, setS3FileName] = useState("");
  const [picodeEntry, setPincodeEntry] = useState("");
  const [showEmptyZip, setShowEmptyZip] = useState(adToedit ? false : true);
  const [addbtnCount, setAddbtnCount] = useState(0);


  const navigate = useNavigate();
  const addData = {
    description: "",
    filekey: "",
    filename: "",
    link: "",
    pincodes: [],
    title: "",
    typeid: 0
  };
  const editData = {
    description: adToedit?.description,
    filekey: adToedit?.filekey,
    filename: adToedit?.filename,
    id: adToedit?.id,
    title: adToedit?.title,
    typeid: adToedit?.typeid,
    link: adToedit?.link,
    pincodes: adToedit?.pincodes
  };
  const [formData, setFormData] = useState(adToedit ? editData : addData);
  const [selectedType, setSelectedType] = useState<number>(adToedit ? adToedit.typeid : undefined);
  const [items, setItems] = useState<{ zip: string }[]>(adToedit ? adToedit.pincodes : []);


  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = async () => {
    if (inputRef.current?.files) {
      const selectedFile = inputRef.current.files[0];
      const fileType = selectedFile.type;
      if (fileType.startsWith('image/') || fileType.startsWith('video/')) {
        if (fileType.startsWith('image/') && selectedType === 2) {
          setErrors({ ...errors, file: "Please upload a video file." });
          return false;
        }
        if (fileType.startsWith('video/') && selectedType === 1) {
          setErrors({ ...errors, file: "Please upload an image file." });
          return false;
        }
        setUploadedFileName(inputRef.current.files[0].name);
        setErrors({ ...errors, file: '' });
        setFormData({ ...formData, ["filename"]: inputRef.current.files[0].name });
        const s3Url = await Putpresigned(inputRef.current.files[0]);
        if (s3Url) {
          uploadToS3(s3Url, inputRef.current.files[0])
        }

      } else {
        setErrors({ ...errors, file: ErrorStrings.AddAdvertisement.InvalidFile });
      }
    }
  };


  const HandleURL = (e: { target: { name: string; value: string } }) => {
    const newValue = e.target.value;
    const isValid = CheckValidURL(newValue);
    if (isValid == true) {
      setErrors({ ...errors, link: '' });
      setFormData({ ...formData, ["link"]: newValue });
    }
    else {
      setFormData({ ...formData, ["link"]: newValue });
      setErrors({ ...errors, link: 'Invalid URL.' });
    }
  };

  const CheckValidURL = (url: string) => {
    try {
      const newUrl = new URL(url);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  const Putpresigned = async (file: any) => {
    if (file) {
      try {
        const { payload } = await dispatch(putpresigned(null) as any);
        if (payload.status === 200) {
          const response = payload.data;
          return response;
        } else {
          console.log(payload.data.error);
          return payload;
        }
      } catch (error) {
        return false;
      }
    }
  }
  const uploadToS3 = async (s3Url: any, file: any) => {
    if (s3Url) {
      const data = {
        s3data: s3Url,
        file: file
      }
      try {
        const { payload } = await dispatch(uploadTos3(data) as any);
        if (payload.status === 200) {
          setS3FileName(s3Url.Name);
          setFormData({ ...formData, ["filekey"]: s3Url.Name });
          setChoosedFile(s3Url.Name);
        } else {
          console.log(payload.data.error);
        }
      } catch (error) {
        return false;
      }
    }
  }


  const addItem = () => {
    if (picodeEntry === '') {
      setErrors({ ...errors, pincodes: ErrorStrings.AddAdvertisement.EmptyZipCode });
    } else {
      let entry = {
        "zip": picodeEntry
      }
      setItems([...items, entry]);
      setPincodeEntry("")
    }
  };

  const addEditZipItem = (count: number) => {
    setShowEmptyZip(true);
    if (picodeEntry === '') {
      if (count === 0) {
        setErrors({ ...errors, pincodes: "" });
        setAddbtnCount(count + 1);
      }
      else {
        setErrors({ ...errors, pincodes: ErrorStrings.AddAdvertisement.EmptyZipCode });
      }
    } else {
      let entry = {
        "zip": picodeEntry
      }
      setItems([...items, entry]);
      setPincodeEntry("")
    }
  };
  const deleteItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(parseInt(event.target.value, 10));
    setErrors({ ...errors, typeid: '' });
    setUploadedFileName(null);
  };

  const validate = () => {
    let isValid = true;
    let focusSet = false;
    const errorss: AddAdvertisementErrors = {};
    if (formData?.title === '') {
      isValid = false;
      errorss.title = ErrorStrings.AddAdvertisement.EmptyTitle;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (selectedType === null || selectedType === undefined) {
      isValid = false;
      errorss.typeid = ErrorStrings.AddAdvertisement.EmptyType;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (uploadedFileName === null) {
      isValid = false;
      errorss.file = ErrorStrings.AddAdvertisement.EmptyFile;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (formData?.link === '') {
      isValid = false;
      errorss.link = ErrorStrings.AddAdvertisement.EmptyWebsite;
      if (!focusSet) {
        focusSet = true;
      }
    }
    if (items !== undefined && items.length === 0) {
      if (picodeEntry === '') {
        errorss.pincodes = ErrorStrings.AddAdvertisement.EmptyZipCode;
      }
      if (!focusSet) {
        focusSet = true;
      }
    }
    setErrors(errorss);
    return isValid;
  };

  const handleChangeInputs = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({ ...formData, [name]: value });
  };

  const HandleZipCode = (e: { target: { name: string; value: string } }) => {
    const newValue = e.target.value;
    const isValid = checkZipValidation(newValue);
    if (isValid == true) {
      setErrors({ ...errors, pincodes: '' });
      setPincodeEntry(newValue);
    }
    else {
      setErrors({ ...errors, pincodes: ErrorStrings.AddAdvertisement.InvalidZipCode });
      setPincodeEntry(newValue);
    }
  };

  const checkZipValidation = (zip: string) => {
    const zipCodeRegex = /^\d{5}(-\d{4})?$/;
    return zipCodeRegex.test(zip);
  }

  const SaveSubmit = async () => {
    const isValid = validate();
    if (isValid) {
      dispatch(changeLoaderStatus(true) as any);
      try {
        let arr = [];
        if (items !== undefined && items.length === 0) {
          if (picodeEntry === '') {
            setErrors({ ...errors, pincodes: ErrorStrings.AddAdvertisement.EmptyZipCode });
            return false;
          }
          else {
            const isValid = checkZipValidation(picodeEntry);
            if (isValid == true) {
              setErrors({ ...errors, pincodes: '' });
              let entry = {
                "zip": picodeEntry
              }
              arr.push(entry);
            }
            else {
              setErrors({ ...errors, pincodes: ErrorStrings.AddAdvertisement.InvalidZipCode });
              return false;
            }
          }
        }
        if (items !== undefined && items.length > 0) {
          if (picodeEntry === '') {
            arr = items;
          }
          else {
            const isValid = checkZipValidation(picodeEntry);
            if (isValid == true) {
              setErrors({ ...errors, pincodes: '' });
              let entry = {
                "zip": picodeEntry
              }
              arr = items;
              arr.push(entry);
              setPincodeEntry("");
            }
            else {
              setErrors({ ...errors, pincodes: ErrorStrings.AddAdvertisement.InvalidZipCode });
              return false;
            }
          }
          console.log("")
        }
        if (formData?.link !== '') {
          const isValidUrl = CheckValidURL(formData.link);
          if (isValidUrl == true) {
            setErrors({ ...errors, link: "" });
          }
          else {
            setErrors({ ...errors, link: "Inavlid URL." });
            return false;
          }
        }
        let info;
        if (adToedit) {
          info = {
            id: adToedit.id,
            description: formData.description,
            filekey: choosedFile,
            filename: uploadedFileName,
            link: formData.link,
            pincodes: arr,
            title: formData.title,
            typeid: selectedType
          }
        }
        else {
          info = {
            description: formData.description,
            filename: uploadedFileName,
            filekey: choosedFile,
            link: formData.link,
            pincodes: arr,
            title: formData.title,
            typeid: selectedType
          }
        }
        console.log("formdata", formData)

        console.log("data", info)
        const { payload } = await dispatch(adCreation(info) as any);
        if (payload.status === 200) {
          handleShow();
        } else {
          toast.error(payload.data.error);
        }
      } catch (error) {
        return false;
      }
    }
  };
  const moveBack = () => {
    navigate('/advertisementlist');
  };

  const handleModalClose = () => {
    handleClose();
    navigate('/advertisementlist');
  };

  const HandleCancel = () => {
    navigate('/advertisementlist');
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      SaveSubmit();
    }
  };

  const getSavedFile = async () => {
    let info = {
      name: formData.filekey,
    }
    const { payload } = await dispatch(getpresigned(info) as any);
    if (payload.status === 200) {
      console.log("data", payload.data);
      window.open(payload.data.URL, "_blank");
    } else {
      toast.error(payload.data.error);
    }
  }

  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="layout-content">
      <Container fluid className="content-wrap">
        <div className="sub-head">
          <Row className="align-items-center header mb-4">
            <Col className="heading d-flex">
              <Button onClick={moveBack}>
                <IcArrowBack />
              </Button>
              <h2>{adToedit ? Strings.EditAdvertisement.heading : Strings.AddAdvertisement.heading}</h2>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={12} xl={8}>
            <div className="Form-cstm mt-4">
              <div className="form-group row">
                <label htmlFor="adTitle" className="col-lg-4 col-form-label">
                  {Strings.AddAdvertisement.AdvertisementTitle}
                </label>
                <Col lg={8}>
                  <PAInput
                    id="AdvertisementTitle"
                    name="title"
                    type="text"
                    placeholder={Strings.AddAdvertisement.Placeholder1}
                    value={formData?.title ?? ''}
                    onChange={handleChangeInputs}
                    alert={errors?.title}
                    onKeyDown={handleKeyPress}
                  />
                </Col>
              </div>
              {/* {adToedit ? <> */}
              <div className="form-group row">
                <label htmlFor="adType" className="col-lg-4 col-form-label">
                  {Strings.AddAdvertisement.type}
                </label>
                <Col lg={8}>
                  <div className="cstm-radio">
                    <Col lg={4} style={{ float: "left" }}><PARadio label={Strings.AddAdvertisement.image} checked={selectedType === 1} type="radio" name="type" value={1} onChange={handleTypeChange} /></Col>
                    <Col lg={4} style={{ float: "right" }}><PARadio label={Strings.AddAdvertisement.video} checked={selectedType === 2} type="radio" name="type" value={2} onChange={handleTypeChange} /></Col>
                  </div>
                  <Form.Control.Feedback style={{ display: 'block' }} type="invalid">
                    {errors?.typeid}
                  </Form.Control.Feedback>
                </Col>
              </div>
              <div className="form-group row">
                <Col lg={4}></Col>
                <Col lg={8}>
                  {uploadedFileName && selectedType === 1 && <>
                    <Col lg={4} style={{ float: "left" }}><IcImage width="100" height="100" />
                      <p className='mt-2'>
                        <Link className="underline" to="#" onClick={getSavedFile}>{uploadedFileName}</Link>
                      </p>
                    </Col><Col lg={4} style={{ float: "right" }}></Col>
                  </>
                  }
                  {uploadedFileName && selectedType === 2 && <>
                    <Col lg={4} style={{ float: "left" }}></Col><Col lg={4} style={{ float: "right" }}><IcVideo width="100" height="100" />
                      <p className='mt-2'>
                        <Link className="underline" to="#" onClick={getSavedFile}>{uploadedFileName}</Link>
                      </p>
                    </Col>
                  </>
                  }
                </Col>
              </div>
              <div className="form-group row">
                <label htmlFor="profUpload" className="col-lg-4 col-form-label">
                  {Strings.AddAdvertisement.upload}
                </label>
                <Col lg={8}>
                  <div className="cstm-upload">
                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file" name="file" />
                    <button type="button" onClick={handleUpload} className={`btn ${uploadedFileName ? 'success' : 'primary'}`}>
                      {'Choose File'}
                    </button>
                  </div>
                  <Form.Control.Feedback style={{ display: 'block' }} type="invalid">
                    {errors?.file}
                  </Form.Control.Feedback>
                </Col>
              </div>
              <div className="form-group row">
                <label htmlFor="website" className="col-lg-4 col-form-label">
                  {Strings.AddAdvertisement.website}
                </label>
                <Col lg={8}>
                  <PAInput
                    id="AdvertisementWebsite"
                    name="link"
                    type="text"
                    placeholder={"Eg: http://www.google.com"}
                    value={formData?.link ?? ''}
                    onChange={HandleURL}
                    alert={errors?.link}
                    onKeyDown={handleKeyPress}
                  />
                </Col>
              </div>
              <div className="form-group row">
                <label htmlFor="description" className="col-lg-4 col-form-label">
                  {Strings.AddAdvertisement.description}
                </label>
                <Col lg={8}>
                  <PATextarea
                    rows="5"
                    name="description"
                    value={formData?.description ?? ''}
                    onChange={handleChangeInputs}
                    alert={errors?.description}
                    onKeyDown={handleKeyPress}
                  />
                </Col>
              </div>
              <div className="form-group row">
                <label htmlFor="zip" className="col-lg-4 col-form-label zip">
                  {Strings.AddAdvertisement.zip}
                </label>
                <Col lg={8}>
                  {adToedit ?
                    <div className="zip-input">
                      {showEmptyZip ?
                        <PAInput
                          id="AdvertisementWebsite"
                          name="pincodes"
                          type="text"
                          placeholder={Strings.AddAdvertisement.ziplaceholder}
                          value={picodeEntry}
                          onChange={HandleZipCode}
                          alert={errors?.pincodes}
                          onKeyDown={handleKeyPress}
                        />
                        : <div className='pa-input'><div className='w-100' style={{ height: "55px" }}></div></div>
                      }
                      <Button variant="outline-primary" title="Add More Zip Codes"
                        onClick={() => addEditZipItem(addbtnCount)}
                      >
                        <IcInputAdd />
                      </Button>
                    </div>
                    :
                    <div className="zip-input">
                      <PAInput
                        id="AdvertisementWebsite"
                        name="pincodes"
                        type="text"
                        placeholder={Strings.AddAdvertisement.ziplaceholder}
                        value={picodeEntry}
                        onChange={HandleZipCode}
                        alert={errors?.pincodes}
                        onKeyDown={handleKeyPress}
                      />
                      <Button variant="outline-primary" title="Add More Zip Codes"
                        onClick={() => addItem()}
                      >
                        <IcInputAdd />
                      </Button>
                    </div>
                  }
                  {items !== undefined && items.length > 0
                    ? items.map((item, index) => (
                      <div className="zip-input">
                        <PAInput
                          id="zipcodeItem"
                          name="zipcodeitem"
                          disabled
                          type="text"
                          placeholder={Strings.AddAdvertisement.ziplaceholder}
                          value={item.zip}
                          onKeyDown={handleKeyPress}
                          onMouseDown={handleMouseDown}
                        />
                        <Button variant="outline-primary" onClick={() => deleteItem(index)}>
                          <IcInputDelete />
                        </Button>
                      </div>
                    ))
                    : null}
                </Col>
              </div>
              <div className="form-group row">
                <label htmlFor="btnLabel" className="col-lg-4 col-form-label" />
                <Col lg={8}>
                  <div className="buttons d-flex justify-content-between">
                    <Button variant="secondary" onClick={HandleCancel}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={SaveSubmit}>
                      {adToedit ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal size="lg" className="cstmmodel" show={show} onHide={handleModalClose} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="icon d-flex justify-content-center">
            <IcSuccessModal />
          </div>
          <h1 className="text-center">{Strings.SuccessDModal1.heading}</h1>
          <p className="text-center">{adToedit ? Strings.SuccessDModal2.Paragraph : Strings.SuccessDModal1.Paragraph}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddAdvertisement;
