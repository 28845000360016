/**
 *@file PASelect.tsx
 *@brief Reusable select component
 *@date November, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import './PASelect.scss';
import React from 'react';
import Select from 'react-select';

const PASelect = ({ ...props }) => {
  return (
    <div style={{ zIndex: 1000 }}>
      <Select
        className={`${props.styleClass ? props.styleClass : 'cstm-select'} `}
        options={props.options}
        classNamePrefix="filter"
        menuPlacement="auto"
        value={props.value}
        onChange={props.onChange}
        isDisabled={props.disabled}
      />
    </div>
  );
};

export default PASelect;
