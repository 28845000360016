/**
 *@file PATextarea.tsx
 *@brief Reusable input component
 *@date December, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */

import { Form } from 'react-bootstrap';
import './PATextarea.scss';

const PATextarea = ({ rows, value, onChange, name, onKeyDown, alert }: any) => {
  return (
    <Form.Group className="pa-textarea">
      <Form.Control as="textarea" rows={rows} value={value} onChange={onChange} name={name} onKeyDown={onKeyDown} />
      <Form.Control.Feedback style={{ display: 'block' }} type="invalid">
        {alert || null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default PATextarea;
