/**
 * @file   src\App.tsx
 * @brief  This file is responsible for routing.
 * @date   November, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './containers/Login';
import ForgotPassword from './containers/ForgotPassword';
import NewPassword from './containers/NewPassword';
import GenericNotFound from './containers/GenericNotFound';
import Layout from './components/Layout';
import ManageUsers from './containers/ManageUser';
import UserDetails from './containers/UserDetails';
import AdvertisementList from './containers/AdvertisementList';
import AddAdvertisement from './containers/AddAdvertisement';
import ChangePassword from './containers/ChangePassword';
import { getAuthToken } from './utils/localStorage';
import IceInvitation from './containers/IceInvitation';

const App = () => {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    // Fetch the token and set it to the state
    const fetchedToken = getAuthToken();
    setToken(fetchedToken);
  }, []);
  return (
    <>
      <ToastContainer position="top-center" theme="colored" newestOnTop transition={Slide} autoClose={5000} />
      <Router>
        <Routes>
          <>
            <Route path="/" element={<Login />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="newpassword" element={<NewPassword />} />
            <Route path="/iceinvitation" element={<IceInvitation />} />
            <Route path="/*" element={<GenericNotFound />} />
          </>
          <Route element={token ? <Layout /> : <Login />}>
            <Route path="/manageusers" element={token ? <ManageUsers /> : <Login />} />
            <Route path="/userdetails" element={token ? <UserDetails /> : <Login />} />
            <Route path="/advertisementlist" element={token ? <AdvertisementList /> : <Login />} />
            <Route path="/addadvertisement" element={token ? <AddAdvertisement /> : <Login />} />
            <Route path="/changepassword" element={token ? <ChangePassword /> : <Login />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
