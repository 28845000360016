/**
 * @file   src\utils\constants.ts
 * @brief  This file is responsible define constants.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

export const STATUS_401 = 401;
export const API_TIMEOUT = 30000;
export const API_MAXCONTENTLENGTH = 1000;
export const API_REDIRECTS = 5;
