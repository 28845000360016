const IcEdit = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Edit" transform="translate(1995 10374)">
        <g id="Rectangle_859" data-name="Rectangle 859" transform="translate(-1995 -10374)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
          <rect width="28" height="28" stroke="none" />
          <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
        </g>
        <g id="pencil-square--change-document-edit-modify-paper-pencil-write-writing" transform="translate(-1990.813 -10370.916)">
          <path
            id="Rectangle_1097"
            data-name="Rectangle 1097"
            d="M16.493,6.491A1.756,1.756,0,0,0,14.862,4.86c-1.069-.086-2.746-.172-5.123-.172s-4.054.087-5.123.172A1.755,1.755,0,0,0,2.985,6.491C2.9,7.56,2.813,9.237,2.813,11.614s.087,4.054.172,5.123a1.756,1.756,0,0,0,1.631,1.631c1.069.085,2.746.172,5.123.172s4.054-.087,5.123-.172a1.756,1.756,0,0,0,1.631-1.631c.085-1.069.172-2.746.172-5.123s-.087-4.054-.172-5.123Z"
            transform="translate(0 -0.487)"
            fill="none"
          />
          <path
            id="Rectangle_62"
            data-name="Rectangle 62"
            d="M23.374,8.161a1.823,1.823,0,0,0,.354-2.41,8.251,8.251,0,0,0-1.519-1.518,1.823,1.823,0,0,0-2.41.354L14.453,9.932a1.137,1.137,0,0,0-.343.692,15.964,15.964,0,0,0,.008,2.595.672.672,0,0,0,.623.623,15.979,15.979,0,0,0,2.595.008,1.14,1.14,0,0,0,.692-.343l5.346-5.346Z"
            transform="translate(-7.094)"
            fill="none"
          />
          <path
            id="Intersect"
            d="M30.974,8.971l.81-.81a1.823,1.823,0,0,0,.354-2.41,8.25,8.25,0,0,0-1.519-1.518,1.823,1.823,0,0,0-2.41.354l-.81.81a7.186,7.186,0,0,1,2.065,1.51,7.193,7.193,0,0,1,1.51,2.065Z"
            transform="translate(-15.504 0)"
            fill="none"
          />
          <path
            id="Rectangle_1098"
            data-name="Rectangle 1098"
            d="M23.374,8.161a1.823,1.823,0,0,0,.354-2.41,8.251,8.251,0,0,0-1.519-1.518,1.823,1.823,0,0,0-2.41.354L14.453,9.932a1.137,1.137,0,0,0-.343.692,15.964,15.964,0,0,0,.008,2.595.672.672,0,0,0,.623.623,15.979,15.979,0,0,0,2.595.008,1.14,1.14,0,0,0,.692-.343l5.346-5.346Z"
            transform="translate(-7.094)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Intersect_2"
            d="M30.973,11.5a7.186,7.186,0,0,0-1.51-2.065A7.189,7.189,0,0,0,27.4,7.924"
            transform="translate(-15.504 -2.527)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Rectangle_1096"
            data-name="Rectangle 1096"
            d="M16.663,11.921c0,.224-.085,3.747-.17,4.815a1.756,1.756,0,0,1-1.631,1.631c-1.069.085-2.746.172-5.123.172s-4.054-.087-5.123-.172a1.756,1.756,0,0,1-1.631-1.631c-.086-1.069-.172-2.746-.172-5.123S2.9,7.56,2.985,6.491A1.755,1.755,0,0,1,4.616,4.86a38.261,38.261,0,0,1,4.123-.172"
            transform="translate(0 -0.487)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcEdit;
