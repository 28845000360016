/**
 * @file   src\store\actions\userAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosClient } from '../../config/axiosConfig';
import { UserApi } from '../../utils/apiUrls';
import { getAuthToken } from '../../utils/localStorage';

export const userList = createAsyncThunk('/user/userList', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(UserApi.USER_LIST, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const updatewellnessdata = createAsyncThunk('/user/updatewellnessdata', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(UserApi.UPDATE_ALERT_STATUS, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const userDetails = createAsyncThunk('/user/userdetails', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(UserApi.USER_DETAILS, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const deleteuser = createAsyncThunk('/user/deleteuser', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(UserApi.USER_DELETE, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});


