/**
 * @file   src\store\slices\authSlices.ts
 * @brief  This file is responsible for creating auth slices to call actions and state management.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { login } from '../actions/authAction';
import { Iauth } from '../../interfaces/authInterface';

// Initial state.
const authState: Iauth = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

// Config user slice.
export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    resetAuthState: () => authState,
  },
  extraReducers(builder) {
    // Start validate user request.
    builder.addCase(login.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
      state.errorCode = 0;
      state.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(login.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = 0;
      state.isLoading = false;
      state.isSuccess = true;
      state.userInfo = data;
      state.errorCode = error;
      state.errorMessage = '';
    });
    // Validate user error.
    builder.addCase(login.rejected, (state, action: any) => {
      state.isSuccess = false;
      state.isLoading = false;
      state.errorCode = action.payload?.data?.code;
      state.errorMessage = action.payload?.data?.error;
    });
  },
});
// Export auth actions.
export const { resetAuthState } = authSlice.actions;
// Export reducer.
export default authSlice.reducer;
