const IcDelete = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="Group_4186" data-name="Group 4186" transform="translate(11234 8947)">
        <g id="Rectangle_904" data-name="Rectangle 904" transform="translate(-11234 -8947)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
          <rect width="28" height="28" stroke="none" />
          <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
        </g>
        <g id="trash" transform="translate(-11229 -8942)">
          <path
            id="Path_13424"
            data-name="Path 13424"
            d="M3,6H15.8"
            transform="translate(0 -0.338)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_13425"
            data-name="Path 13425"
            d="M14.137,5.76V14.8a1.367,1.367,0,0,1-1.305,1.422H6.305A1.367,1.367,0,0,1,5,14.8V5.76m1.958-.916V3.422A1.367,1.367,0,0,1,8.263,2h2.61a1.367,1.367,0,0,1,1.305,1.422V4.844"
            transform="translate(-0.169)"
            fill="none"
            stroke="#0b4aa5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default IcDelete;
