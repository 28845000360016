/**
 * @file   src\containers\ForgotPassword.tsx
 * @brief  ForgotPassword page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../assets/styles/prelogin.scss';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState, KeyboardEvent } from 'react';
import { toast } from 'react-toastify';
import logo from '../assets/img/logo-prelogin.png';
import Strings from '../assets/strings/Strings.json';
import PAInput from '../components/PAInput';
import { ForgotPasswordErrors } from '../interfaces/authInterface';
import ErrorStrings from '../assets/strings/ErrorStrings.json';
import { validationEmail } from '../helpers/functions';
import { useAppDispatch } from '../hooks';
import { forgotpwd } from '../store/actions/authAction';
import { SetForgotPasswordEmail } from '../utils/localStorage';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({ email: '' });
  const [errors, setErrors] = useState<ForgotPasswordErrors>({});

  const validate = () => {
    let isValid = true;
    let focusSet = false;
    const errorss: ForgotPasswordErrors = {};
    if (formData?.email === '') {
      isValid = false;
      errorss.email = ErrorStrings.ForgotPassword.EmptyEmail;
      if (!focusSet) {
        focusSet = true;
      }
    } else if (!validationEmail(formData?.email)) {
      isValid = false;
      errorss.email = ErrorStrings.LoginValidation.ValidEmail;
      if (!focusSet) {
        focusSet = true;
      }
    }
    setErrors(errorss);
    return isValid;
  };

  const handleChangeInputs = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    const isValid = validate();
    if (isValid) {
      try {
        const info = {
          Email: formData.email,
        };
        const { payload } = await dispatch(forgotpwd(info) as any);
        if (payload.status === 200) {
          SetForgotPasswordEmail(formData.email);
          toast.success(Strings.ForgotPassword.SuccesssMsg);
        } else {
          toast.error(payload.error);
        }
      } catch (error) {
        return false;
      }
    }
    return undefined;
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Container fluid className="login_bg">
      <Container className="h-100">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col lg={6} className="d-flex align-items-center justify-content-center">
            <img className="logo" src={logo} alt="logo" />
          </Col>
          <Col lg={6}>
            <div className="login w-100">
              <h1>{Strings.ForgotPassword.Title}</h1>
              <p className="mb-5 forgotLabel">{Strings.ForgotPassword.Paragraph}</p>
              <div className="mt-25">
                <PAInput
                  id="email"
                  name="email"
                  type="email"
                  placeholder={Strings.ForgotPassword.Placeholder1}
                  value={formData?.email ?? ''}
                  onChange={handleChangeInputs}
                  alert={errors?.email}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <Button className="mt-4" size="lg" type="submit" onClick={onSubmit}>
                {Strings.ForgotPassword.button}
              </Button>
              <p className="mt-5 forgotLabel">
                {Strings.ForgotPassword.LinkText}
                <Link className="underline" to="/">
                  {Strings.ForgotPassword.Link}
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ForgotPassword;
