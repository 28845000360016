/**
 * @file   src\store\actions\uadvertisementAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosClient } from '../../config/axiosConfig';
import { AdvertisementApi } from '../../utils/apiUrls';
import { getAuthToken } from '../../utils/localStorage';
import axios from 'axios';
import { debug } from 'console';

export const advertisementlist = createAsyncThunk('/advertisement/advertisementlist', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(AdvertisementApi.ADVERTISEMENT_LIST, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const putpresigned = createAsyncThunk('/advertisement/putpresigned', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.get(AdvertisementApi.ADVERTISEMENT_PUT_PRESIGNED, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const uploadTos3 = createAsyncThunk('/advertisement/uploadTos3', async (payload: any, { rejectWithValue }) => {
  try {
    console.log("data",payload);
    const headers = { APIKey: process.env.REACT_APP_API_KEY };
    const data = await axios.put(payload.s3data.URL, payload.file, {headers});
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const adCreation = createAsyncThunk('/advertisement/createorupdateadvertisement', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(AdvertisementApi.ADVERTISEMENT_ADD_EDIT_AD, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const updateadstatus = createAsyncThunk('/advertisement/updateadstatus', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const data = await axiosClient.post(AdvertisementApi.ADVERTISEMENT_EDIT_AD_STATUS, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

// export const getpresigned = createAsyncThunk('/getpresigned', async (payload: any, { rejectWithValue }) => {
//   try {
//     const headers = { Authorization: `Bearer ${getAuthToken()}` };
//     const data = await axiosClient.get(AdvertisementApi.GET_SAVED_S3_AD_FILE, payload, { headers });
//     return data;
//   } catch (error: any) {
//     return rejectWithValue(error.response?.data);
//   }
// });

export const getpresigned = createAsyncThunk('/getpresigned', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: `Bearer ${getAuthToken()}`, APIKey: process.env.REACT_APP_API_KEY };
    const queryParams = new URLSearchParams(payload).toString();
    const data = await axiosClient.get(`${AdvertisementApi.GET_SAVED_S3_AD_FILE}?${queryParams}`, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});






