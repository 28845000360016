/**
 *@file PAInput.tsx
 *@brief Reusable input component
 *@date November, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React from 'react';
import { Form } from 'react-bootstrap';
import { InputProperty } from '../interfaces/GeneralInterface';
import './PAInput.scss';

type PAInputPrp = InputProperty;

const PAInput = React.forwardRef<HTMLInputElement, PAInputPrp>((props, ref) => {
  return (
    <Form.Group className="pa-input">
      <Form.Control
        id={props.id}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        autoComplete="off"
        onKeyDown={props.onKeyDown}
        ref={ref}
        onMouseDown={props.onMouseDown}
      />
      <Form.Control.Feedback style={{ display: 'block' }} type="invalid">
        {props?.alert}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

export default PAInput;
